import React, { useEffect, useState } from "react";
import "./profileview.css";
import { useNavigate } from "react-router-dom";
import { useProfileDetailsData } from "../../hooks/profiles";
import Uploadimg from "./Uploadimg";

const ProfileView = ({ handleClose }) => {
  const navigate = useNavigate();
  const data = useProfileDetailsData();
  const [profileData, setProfileData] = useState([]);
  const [showUploadModals, setShowUploadModals] = useState(false);

  useEffect(() => {
    setProfileData(data?.data?.data?.data);
  }, [data]);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  const handlePencilClick = () => {
    setShowUploadModals(true);
    // handleClose(); // Close ProfileView modal
  };
  return (
    <div>
      <div className="modal" tabIndex="-1">
        {profileData?.map((items, index) => {
          return (
            <div className="modal-dialog" key={index}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Profile</h5>
                  <button
                    type="button"
                    className="cancelbtt"
                    onClick={handleClose}
                  >
                    <i
                      className="fa-solid fa-xmark fa-xl"
                      style={{ color: "var(--secondary-text-color)" }}
                    ></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="profileimg">
                    <img
                      src={items?.self[0]?.img || "/assest/default-avtar.svg"}
                      alt=""
                    />
                    <i
                      className="fa-solid fa-pencil fa-xl"
                      style={{
                        position: "absolute",
                        bottom: "15px",
                        left: "150px",
                      }}
                      onClick={handlePencilClick}
                    ></i>
                  </div>
                  <div className="profuledetails">
                    <p>
                      <span>Name: </span>
                      {items?.name}
                    </p>
                    <p>
                      <span>UserName: </span>
                      {items?.userName}
                    </p>

                    <p>
                      <span>Phone Number: </span>
                      {items?.phone}
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                    style={{ background: "var( --button-color-second)" }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ background: "var( --button-color)" }}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {showUploadModals && (
        <Uploadimg handleCloses={() => setShowUploadModals(false)} />
      )}
    </div>
  );
};

export default ProfileView;
