import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDSLNonReport } from "../../../hooks/addreport";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  initialValuesNonTicket,
  validationSchemaNonTicket,
} from "../../../validations";
import { BeatLoader } from "react-spinners";
import { useStateContext } from "../../../utls/Context";

const EditNonTicketCollector = () => {
  const location = useLocation();
  const datas = location.state;
  const dataid = datas?._id?.documentid;
  const navigate = useNavigate();
  const {logout} = useStateContext();
  const [numOnlineDeliveryForms, setNumOnlineDeliveryForms] = useState(
    datas?.documents?.[0]?.onlineDeliveryOrder?.length
  );
  const [numcomplimentaryForms, setNumcomplimentaryForms] = useState(
    datas?.documents?.[0]?.complimentaryDetails?.length
  );
  const {
    mutateAsync: muteEditNonTicket,
    isLoading,
    isSuccess,
    isError,
    data,
    error,
  } = useDSLNonReport();

  const handleclickback = () => {
    navigate(-1);
  };

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...initialValuesNonTicket,
      cashFlowDetails: {
        ...datas?.documents?.[0]?.cashFlowDetails,
      },
      onlineDeliveryOrder: [...datas?.documents?.[0]?.onlineDeliveryOrder],
      complimentaryDetails: [...datas?.documents?.[0]?.complimentaryDetails],
    },

    validationSchema: validationSchemaNonTicket,
    onSubmit: async (values) => {
      try {
        const { cashFlowDetails, onlineDeliveryOrder, complimentaryDetails } =
          values;

        const payload = {
          cashFlowDetails,
          onlineDeliveryOrder,
          complimentaryDetails,
        };

        await muteEditNonTicket({ dataid, payload });
      } catch (error) {
        console.error("Error during login:", error);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = data;
      toast.success(message);

      if (status === 200) {
        resetForm();
        setNumOnlineDeliveryForms(0);
        setNumcomplimentaryForms(0);
        navigate("/salereport");
      }
    }

    if (isError) {
      toast.error(error.response.data.message);
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout();
      }
    }
  }, [isError, isSuccess, data, error, navigate, resetForm, logout]);

  const calculateCash = () => {
    const {
      // notes2000,
      notes500,
      notes200,
      notes100,
      notes50,
      notes20,
      notes10,
      notes5,
      notes2,
      notes1,
    } = values.cashFlowDetails;
    const totalCash =
      // notes2000 * 2000 +
      notes500 * 500 +
      notes200 * 200 +
      notes100 * 100 +
      notes50 * 50 +
      notes20 * 20 +
      notes10 * 10 +
      notes5 * 5 +
      notes2 * 2 +
      notes1 * 1;
    return totalCash ? totalCash : 0;
  };
  const calculateTotalCash = () => {
    const { cash, upi, voucher, razorpay } = values.cashFlowDetails;
    const totalCash = cash * 1 + upi * 1 + voucher * 1 + razorpay * 1;
    return totalCash;
  };

  useEffect(() => {
    setFieldValue(
      "cashFlowDetails.cash",
      calculateCash()
      // ? calculateCash() : ""
    );

    setFieldValue(
      "cashFlowDetails.totalCash",
      calculateTotalCash() ? calculateTotalCash() : ""
    );
    // eslint-disable-next-line
  }, [values]);

  const handleAddOnlineDeliveryForm = () => {
    setNumOnlineDeliveryForms(numOnlineDeliveryForms + 1);
    const newonlineDelivery = {
      invoice: "",
      amount: "",
      aggregator: "",
      remarks: "",
    };
    setFieldValue(
      `onlineDeliveryOrder[${numOnlineDeliveryForms}]`,
      newonlineDelivery
    );
  };

  const handleOnlineDeliveryChecked = (text) => {
    if (text === "yes") {
      setNumOnlineDeliveryForms(1);
      const newonlineDelivery = {
        invoice: "",
        amount: "",
        aggregator: "",
        remarks: "",
      };
      setFieldValue(
        `onlineDeliveryOrder[${numOnlineDeliveryForms}]`,
        newonlineDelivery
      );
    }
  };

  const handleRemoveOnlineDeliveryForm = () => {
    if (numOnlineDeliveryForms > 0) {
      setNumOnlineDeliveryForms(numOnlineDeliveryForms - 1);

      const updatedOnlineDeliveryDetails = [...values.onlineDeliveryOrder];
      updatedOnlineDeliveryDetails.pop(); // Remove the last element

      // Update the form values with the modified ticketDetails array
      setFieldValue("onlineDeliveryOrder", updatedOnlineDeliveryDetails);
    }
  };

  const handleaddcomplimentaryForm = () => {
    setNumcomplimentaryForms(numcomplimentaryForms + 1);
    const newcomplimentary = {
      name: "",
      items: "",
      quantity: "",
      unitCost: "",
      givenBy: "",
      remarks: "",
    };
    setFieldValue(
      `complimentaryDetails[${numcomplimentaryForms}]`,
      newcomplimentary
    );
  };

  const handlecomplimentaryChecked = (text) => {
    if (text === "yes") {
      setNumcomplimentaryForms(1);
      const newcomplimentary = {
        name: "",
        items: "",
        quantity: "",
        unitCost: "",
        givenBy: "",
        remarks: "",
      };
      setFieldValue(
        `complimentaryDetails[${numcomplimentaryForms}]`,
        newcomplimentary
      );
    }
  };

  const handleremovecomplimentaryForm = () => {
    if (numcomplimentaryForms > 0) {
      setNumcomplimentaryForms(numcomplimentaryForms - 1);

      const updatedCompDetails = [...values.complimentaryDetails];
      updatedCompDetails.pop(); // Remove the last element

      // Update the form values with the modified ticketDetails array
      setFieldValue("complimentaryDetails", updatedCompDetails);
    }
  };
  return (
    <div>
      <div className="addreport">
        <div className="addwrapper">
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleclickback}
          >
            <i
              className="fa-solid fa-arrow-left"
              style={{
                color: "var(--secondary-text-color)",
                fontWeight: "800",
                cursor: "pointer",
              }}
            >
              {" "}
            </i>
            <p style={{ fontSize: "18px", fontWeight: 700, margin: "0px" }}>
              Back
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <h2> Edit your DSL Report</h2>
            <i
              className="fa-solid fa-pen"
              style={{ color: "var(--fourth-text-color)", marginTop: "8px" }}
            ></i>
          </div>

          <div className="addreportwrapper">
            <p> Add cash Flow Details</p>
            <form onSubmit={handleSubmit}>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label>Counter Name</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  // eslint-disable-next-line no-undef
                  type="text"
                  placeholder="No of Notes"
                  name="cashFlowDetails.counterName"
                  value={values.cashFlowDetails.counterName}
                  onChange={handleChange}
                  readOnly // Add readOnly attribute here
                  style={{ cursor: "default" }}
                ></input>
                {errors.cashFlowDetails?.counterName &&
                  touched.cashFlowDetails?.counterName && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.counterName}
                    </div>
                  )}
              </div>

              {/* <div className="formsectionwrapper">
              <div className="labelwraper">
                <label> 2000 Notes</label>
                <label className="dotlabel">:</label>
              </div>
              <input
                type="text"
                placeholder="No of Notes"
                value={values.cashFlowDetails.notes2000}
                name="cashFlowDetails.notes2000"
                onChange={handleChange}
              />
              {errors.cashFlowDetails?.notes2000 &&
                touched.cashFlowDetails?.notes2000 && (
                  <div className="error-message">
                    {errors.cashFlowDetails?.notes2000}
                  </div>
                )}
            </div> */}
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 500 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes500}
                  name="cashFlowDetails.notes500"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes500 &&
                  touched.cashFlowDetails?.notes500 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes500}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 200 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes200}
                  name="cashFlowDetails.notes200"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes200 &&
                  touched.cashFlowDetails?.notes200 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes200}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 100 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes100}
                  name="cashFlowDetails.notes100"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes100 &&
                  touched.cashFlowDetails?.notes100 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes100}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 50 Notes</label>
                  <label className="dotlabel">:</label>
                </div>

                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes50}
                  name="cashFlowDetails.notes50"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes50 &&
                  touched.cashFlowDetails?.notes50 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes50}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 20 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes20}
                  name="cashFlowDetails.notes20"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes20 &&
                  touched.cashFlowDetails?.notes20 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes20}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 10 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes10}
                  name="cashFlowDetails.notes10"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes10 &&
                  touched.cashFlowDetails?.notes10 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes10}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 5 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes5}
                  name="cashFlowDetails.notes5"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes5 &&
                  touched.cashFlowDetails?.notes5 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes5}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 2 Coins</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Coins"
                  value={values.cashFlowDetails.notes2}
                  name="cashFlowDetails.notes2"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes2 &&
                  touched.cashFlowDetails?.notes2 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes2}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 1 Coins</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Coins"
                  value={values.cashFlowDetails.notes1}
                  name="cashFlowDetails.notes1"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes1 &&
                  touched.cashFlowDetails?.notes1 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes1}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> Cash</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Cash"
                  value={calculateCash()}
                  name="cashFlowDetails.cash"
                  onChange={handleChange}
                  style={{ cursor: "default" }}
                />
                {errors.cashFlowDetails?.cash &&
                  touched.cashFlowDetails?.cash && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.cash}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> UPI</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter UPI Amount"
                  value={values.cashFlowDetails.upi}
                  name="cashFlowDetails.upi"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.upi &&
                  touched.cashFlowDetails?.upi && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.upi}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> Razorpay</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Razorpay Amount"
                  value={values.cashFlowDetails.razorpay}
                  name="cashFlowDetails.razorpay"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.razorpay &&
                  touched.cashFlowDetails?.razorpay && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.razorpay}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> Voucher</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter voucher Amount"
                  value={values.cashFlowDetails.voucher}
                  name="cashFlowDetails.voucher"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.voucher &&
                  touched.cashFlowDetails?.voucher && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.voucher}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> Total</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Total Amount"
                  // value={values.cashFlowDetails.totalCash}
                  value={calculateTotalCash()}
                  name="cashFlowDetails.totalCash"
                  onChange={handleChange}
                  style={{ cursor: "default" }}
                />
                {errors.cashFlowDetails?.totalCash &&
                  touched.cashFlowDetails?.totalCash && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.totalCash}
                    </div>
                  )}
              </div>
            </form>
          </div>

          <div className="ticketcollector">
            <p> Online Delivery Order</p>

            {numOnlineDeliveryForms === 0 ? (
              <div className="radiowrapperclass">
                <div className="label">
                  <label for="yes_no_radio">
                    Do you Want to add Online Delivery?
                  </label>
                  <label className="dot" style={{ marginRight: "10px" }}>
                    :
                  </label>
                </div>

                <div className="radiowrapper">
                  <p>
                    <input
                      type="checkbox"
                      name="delivery"
                      onClick={() => {
                        handleOnlineDeliveryChecked("yes");
                      }}
                    />
                  </p>
                </div>
              </div>
            ) : (
              <>
                {[...Array(numOnlineDeliveryForms)].map((_, index) => (
                  <form onSubmit={handleSubmit} key={index}>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Invoice No.</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Invoice Number"
                        value={values.onlineDeliveryOrder[index]?.invoice}
                        name={`onlineDeliveryOrder[${index}].invoice`}
                        onChange={handleChange}
                      />
                      {errors.onlineDeliveryOrder?.[index]?.invoice &&
                        touched.onlineDeliveryOrder?.[index]?.invoice && (
                          <div className="error-message">
                            {errors.onlineDeliveryOrder?.[index]?.invoice}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Amount</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Amount"
                        value={values.onlineDeliveryOrder[index]?.amount}
                        name={`onlineDeliveryOrder[${index}].amount`}
                        onChange={handleChange}
                      />
                      {errors.onlineDeliveryOrder?.[index]?.amount &&
                        touched.onlineDeliveryOrder?.[index]?.amount && (
                          <div className="error-message">
                            {errors.onlineDeliveryOrder?.[index]?.amount}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label>Aggregator</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <select
                        name={`onlineDeliveryOrder[${index}].aggregator`}
                        value={values.onlineDeliveryOrder[index]?.aggregator}
                        onChange={handleChange}
                      >
                        <option value="">Select Aggregator Type</option>
                        <option value="Swiggy ">Swiggy </option>
                        <option value="Zomato">Zomato</option>
                        <option value="Magicpin">Magicpin</option>
                        <option value="Others">Others</option>
                      </select>
                      {errors.onlineDeliveryOrder?.[index]?.aggregator &&
                        touched.onlineDeliveryOrder?.[index]?.aggregator && (
                          <div className="error-message">
                            {errors.onlineDeliveryOrder?.[index]?.aggregator}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Remarks</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Remarks"
                        value={values.onlineDeliveryOrder[index]?.remarks}
                        name={`onlineDeliveryOrder[${index}].remarks`}
                        onChange={handleChange}
                      />
                      {errors.onlineDeliveryOrder?.[index]?.remarks &&
                        touched.onlineDeliveryOrder?.[index]?.remarks && (
                          <div className="error-message">
                            {errors.onlineDeliveryOrder?.[index]?.remarks}
                          </div>
                        )}
                    </div>
                    {index > -1 && index < numOnlineDeliveryForms - 1 && (
                      <div
                        className="horizontaldivider"
                        style={{ margin: "10px" }}
                      ></div>
                    )}
                  </form>
                ))}

                <div className="addticketdetails">
                  {numOnlineDeliveryForms > 0 && (
                    <button
                      className="btt"
                      onClick={handleRemoveOnlineDeliveryForm}
                    >
                      Sub
                    </button>
                  )}
                  <button className="btt" onClick={handleAddOnlineDeliveryForm}>
                    Add
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="ticketcollector">
            <p> Complimentary Details</p>

            {numcomplimentaryForms === 0 ? (
              <div className="radiowrapperclass">
                <div className="label">
                  <label for="yes_no_radio">
                    Do you Want to add complimentary details?
                  </label>
                  <label className="dot" style={{ marginRight: "10px" }}>
                    :
                  </label>
                </div>

                <div className="radiowrapper">
                  <p>
                    <input
                      type="checkbox"
                      name="delivery"
                      onClick={() => {
                        handlecomplimentaryChecked("yes");
                      }}
                    />
                  </p>
                </div>
              </div>
            ) : (
              <>
                {[...Array(numcomplimentaryForms)].map((_, indexes) => (
                  <form onSubmit={handleSubmit} key={indexes}>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Name</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        value={values.complimentaryDetails[indexes]?.name}
                        name={`complimentaryDetails[${indexes}].name`}
                        onChange={handleChange}
                      />
                      {errors.complimentaryDetails?.[indexes]?.name &&
                        touched.complimentaryDetails?.[indexes]?.name && (
                          <div className="error-message">
                            {errors.complimentaryDetails?.[indexes]?.name}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Items</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Items"
                        value={values.complimentaryDetails[indexes]?.items}
                        name={`complimentaryDetails[${indexes}].items`}
                        onChange={handleChange}
                      />
                      {errors.complimentaryDetails?.[indexes]?.items &&
                        touched.complimentaryDetails?.[indexes]?.items && (
                          <div className="error-message">
                            {errors.complimentaryDetails?.[indexes]?.items}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Quantity</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter quantity"
                        value={values.complimentaryDetails[indexes]?.quantity}
                        name={`complimentaryDetails[${indexes}].quantity`}
                        onChange={handleChange}
                      />
                      {errors.complimentaryDetails?.[indexes]?.quantity &&
                        touched.complimentaryDetails?.[indexes]?.quantity && (
                          <div className="error-message">
                            {errors.complimentaryDetails?.[indexes]?.quantity}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Unit Cost</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Unit Cost"
                        value={values.complimentaryDetails[indexes]?.unitCost}
                        name={`complimentaryDetails[${indexes}].unitCost`}
                        onChange={handleChange}
                      />
                      {errors.complimentaryDetails?.[indexes]?.unitCost &&
                        touched.complimentaryDetails?.[indexes]?.unitCost && (
                          <div className="error-message">
                            {errors.complimentaryDetails?.[indexes]?.unitCost}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Given BY</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter items Given BY"
                        value={values.complimentaryDetails[indexes]?.givenBy}
                        name={`complimentaryDetails[${indexes}].givenBy`}
                        onChange={handleChange}
                      />
                      {errors.complimentaryDetails?.[indexes]?.givenBy &&
                        touched.complimentaryDetails?.[indexes]?.givenBy && (
                          <div className="error-message">
                            {errors.complimentaryDetails?.[indexes]?.givenBy}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Remarks</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Remarks"
                        value={values.complimentaryDetails[indexes]?.remarks}
                        name={`complimentaryDetails[${indexes}].remarks`}
                        onChange={handleChange}
                      />
                      {errors.complimentaryDetails?.[indexes]?.remarks &&
                        touched.complimentaryDetails?.[indexes]?.remarks && (
                          <div className="error-message">
                            {errors.complimentaryDetails?.[indexes]?.remarks}
                          </div>
                        )}
                    </div>

                    {indexes > -1 && indexes < numcomplimentaryForms - 1 && (
                      <div
                        className="horizontaldivider"
                        style={{ margin: "10px" }}
                      ></div>
                    )}
                  </form>
                ))}

                <div className="addticketdetails">
                  {numcomplimentaryForms > 0 && (
                    <button
                      className="btt"
                      onClick={handleremovecomplimentaryForm}
                    >
                      Sub
                    </button>
                  )}
                  <button className="btt" onClick={handleaddcomplimentaryForm}>
                    Add
                  </button>
                </div>
              </>
            )}
          </div>

          <button className="btt" type="submit" onClick={handleSubmit}>
            {" "}
            {isLoading ? (
              <BeatLoader size={8} color={"#ffffff"} />
            ) : (
              "Submit Now"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNonTicketCollector;
