import React, { useEffect } from "react";
import { useCounter } from "../../../hooks/counter";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { initialcounter, counterSchema } from ".././../../validations";
import "./addcounter.css";
import { useNavigate } from "react-router-dom";
const AddCounter = () => {
  const navigate = useNavigate();
  const {
    mutateAsync: muteUpload,
    isLoading,
    isSuccess,
    isError,
    data: uploaddata,
    error,
  } = useCounter();

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: initialcounter,
    validationSchema: counterSchema,
    onSubmit: async (values) => {
      try {
        await muteUpload(values);
      } catch (error) {
        console.error("Error during conter Addition:", error);
        toast.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = uploaddata;
      toast.success(message);

      if (status === 200) {
        resetForm();
      }
    }

    if (isError) {
      toast.error("Something went wrong");
      if (error?.response?.data?.status === 401) {
        navigate("/");
      }
    }
  }, [uploaddata, isError, isSuccess, error, navigate, resetForm]);

  return (
    <div>
      <div className="uploadfiles">
        {/* <Navbar /> */}

        <div className="uploadwrapper">
          <div className="heading">
            <p>Add Counter</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="fileinput">
              <div className="label">
                <label>Counter Name</label>
                <label style={{ marginRight: "10px" }}>:</label>
              </div>
              <div className="inputtype">
                <input
                  type="text"
                  placeholder="Enter Counter Name"
                  name="counterName"
                  value={values.counterName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.counterName && touched.counterName && (
                  <div className="error-message">{errors.counterName}</div>
                )}
              </div>
            </div>

            <div className="fileinput">
              <div className="label">
                <label>Counter Type</label>
                <label style={{ marginRight: "10px" }}>:</label>
              </div>
              <div className="inputtype">
                <select
                  name="counterType"
                  value={values.counterType}
                  onChange={handleChange}
                >
                  <option value="">Select Counter Type</option>
                  <option value="ticketcollector">ticketcollector</option>
                  <option value="nonticketcollector">nonticketcollector</option>
                </select>

                {errors.counterType && touched.counterType && (
                  <div className="error-message">{errors.counterType}</div>
                )}
              </div>
            </div>

            <div className="button">
              <button type="submit" className="btt">
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Submit Now"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCounter;
