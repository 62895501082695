import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./forgetpassword.css";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { useChangePassword } from "../../hooks/forgetpassword";
import { initialpasswordchange, passwordChangeSchema } from "../../validations";
import { eyeOpen, eyeClose } from "../../utls";

const Forgetpassword3 = () => {
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [Active, setActive] = useState(false);

  const navigate = useNavigate();
  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useChangePassword();
  useEffect(() => {
    if (isSuccess) {
      if (data?.status === 200) {
        toast.success(data?.message);
        localStorage.removeItem("phoneNumber");
        localStorage.removeItem("access_token");

        navigate("/");

        resetForm();
      } else {
        toast.error("Password invalid ");
        if (error?.response?.data?.status === 401) {
          navigate("/");
        }
      }
    }

    if (isError) {
      toast.error("Something went wrong");
    }
    // eslint-disable-next-line
  }, [isError, isSuccess, data, navigate]);

  const {
    errors,
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialpasswordchange,

    validationSchema: passwordChangeSchema,
    onSubmit: async (values) => {
      try {
        if (values.password !== values.confirmPassword) {
          setPasswordMismatch(true);
          return; // Do not submit if passwords don't match
        }
        // eslint-disable-next-line
        const response = await mutateAsync({ password: values.password });
        console.log("🚀 ~ onSubmit: ~ response?.data:", response);

        // Set user ID in local storage
      } catch (error) {
        console.error("Error during phone:", error);
        // toast.error("Something went wrong");
      }
    },
  });

  return (
    <div>
      <div className="forgetpage">
        <div className="forget">
          <form onSubmit={handleSubmit}>
            <div className="forminput">
              <label> Password*</label>
              <div className="input-container">
                <input
                  type={Active ? "text" : "password"}
                  placeholder="Enter your Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>
                  <span onClick={() => setActive(!Active)}>
                    {Active ? eyeOpen : eyeClose}
                  </span>
                </span>
              </div>
              {touched.password && errors.password && (
                <div className="error-messages">{errors.password}</div>
              )}
            </div>
            <div className="forminput">
              <label>Confirm Password*</label>
              <div className="input-container">
                <input
                  type={Active ? "text" : "password"}
                  placeholder="Enter your Confirm Password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span>
                  <span onClick={() => setActive(!Active)}>
                    {Active ? eyeOpen : eyeClose}
                  </span>
                </span>
              </div>
              {passwordMismatch && (
                <div className="error-messages">Passwords do not match</div>
              )}
            </div>
            <div className="bttn">
              {/* <button className='btt1'> Cancel</button> */}
              <button className="btt1" type="submit">
                {" "}
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
          <Link to="/" style={{ textDecoration: "none" }}>
            <p className="forgetback">Back to login page</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword3;
