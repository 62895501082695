import React, { useEffect, useState } from "react";
import CounterItem from "./CounterItem";
import './addcounter.css'

import { useCounterName } from "../../../hooks/counter";
import { useNavigate } from "react-router-dom";

const CounterDetails = () => {
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState(false);

  const { data: counterdata, refetch } = useCounterName();

  const handleCounter = () => {
    navigate("/counterdetails/addcounter");
  };

  useEffect(() => {
    if (reloadData) {
      
      refetch();
      setReloadData(false);
    }
  }, [reloadData, refetch]);
  return (
    <div>
      <div className="uploaddetails">

        <div className="uploadwrapper">
          <div className="contercontent">
            <div className="heading">
              <p>Counter:-</p>
            </div>
            <div className="counterbtt">
              <button className="btt" onClick={handleCounter}>
                Add Counter
              </button>
            </div>
          </div>

          <div className="counteritem">
            {counterdata?.data?.data?.map((item, index) => {
              return (
                <div className="colcounter" key={index}>
                  <CounterItem
                    countername={item?.counterName}
                    countertype={item?.counterType}
                    id={item?._id}
                    onDeleteSuccess={() => setReloadData(true)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterDetails;
