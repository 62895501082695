import React from "react";
// import SaleReport from "../Admin/saleReport/SaleReport";
import SaleReportDublicate from "../Admin/saleReport/SaleReportDublicate";

const OpertionalManager = () => {
  return (
    <div>
      <SaleReportDublicate />
    </div>
  );
};

export default OpertionalManager;
