// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* colors.css */

:root {
  --primary-background: #ffffff;
  --secondary-background: #f5f5f5;
  --third-background:#00ff01;
  --primary-text-color: #ffffff;
  --secondary-text-color: #000000 ;
  --third-text-color:#81a0d8;
  --fourth-text-color:#FFDE59;
  --placeholder-text-color:#999999;
  --error-text-color:red;

  --button-color:#0d6efd;
  --button-color-second:#6c757d;


}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,eAAe;;AAEf;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,0BAA0B;EAC1B,6BAA6B;EAC7B,gCAAgC;EAChC,0BAA0B;EAC1B,2BAA2B;EAC3B,gCAAgC;EAChC,sBAAsB;;EAEtB,sBAAsB;EACtB,6BAA6B;;;AAG/B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* colors.css */\n\n:root {\n  --primary-background: #ffffff;\n  --secondary-background: #f5f5f5;\n  --third-background:#00ff01;\n  --primary-text-color: #ffffff;\n  --secondary-text-color: #000000 ;\n  --third-text-color:#81a0d8;\n  --fourth-text-color:#FFDE59;\n  --placeholder-text-color:#999999;\n  --error-text-color:red;\n\n  --button-color:#0d6efd;\n  --button-color-second:#6c757d;\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
