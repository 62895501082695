import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDSLReport } from "../../../hooks/addreport";
import { useBandDetails } from "../../../hooks/bandcolour";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  initialValuesAddReport,
  validationSchemaAddReport,
} from "../../../validations";
import { BeatLoader } from "react-spinners";
import { useComboTypeDetails } from "../../../hooks/coboType";
import { useStateContext } from "../../../utls/Context";

const EditTicketCollector = () => {
  const location = useLocation();
  const datas = location.state;
  const dataid = datas?._id?.documentid;
  const {logout} = useStateContext();
  const navigate = useNavigate();
  const { data: combodata } = useComboTypeDetails();

  const { mutateAsync, isLoading, isSuccess, isError, data, error } =
    useDSLReport();

  const { data: banddata } = useBandDetails();
  const [bandColour, setBandColour] = useState([]);

  const [numTicketForms, setNumTicketForms] = useState(
    datas?.documents?.[0]?.ticketDetails?.length
  );
  const [numPartyForms, setNumPartyForms] = useState(
    datas?.documents?.[0]?.partyDetails?.length
  );
  const [comboType, setComboType] = useState([]);


  useEffect(() => {
    setBandColour(banddata?.data?.data);
  }, [banddata]);

  useEffect(() => {
    setComboType(combodata?.data?.data);
  }, [combodata]);

  const handleAddPartyForm = () => {
    setNumPartyForms(numPartyForms + 1);
    const newParty = {
      partyName: "",
      // pnotes2000: "",
      pnotes500: "",
      pnotes200: "",
      pnotes100: "",
      pnotes50: "",
      pnotes20: "",
      pnotes10: "",
      pnotes5: "",
      pnotes2: "",
      pnotes1: "",
      pcash: "",
      pupi: "",
      prazorpay: "",

      ptotalCash: "",
    };
    setFieldValue(`partyDetails[${numPartyForms}]`, newParty);
  };

  const handleChecked = (text) => {
    if (text === "yes") {
      setNumPartyForms(1);
      const newParty = {
        partyName: "",
        // pnotes2000: "",
        pnotes500: "",
        pnotes200: "",
        pnotes100: "",
        pnotes50: "",
        pnotes20: "",
        pnotes10: "",
        pnotes5: "",
        pnotes2: "",
        pnotes1: "",
        pcash: "",
        pupi: "",
        prazorpay: "",

        ptotalCash: "",
      };
      setFieldValue(`partyDetails[${numPartyForms}]`, newParty);
    }
  };

  const handleRemovePartyForm = () => {
    if (numPartyForms > 0) {
      setNumPartyForms(numPartyForms - 1);

      const updatedPartyDetails = [...values.partyDetails];
      updatedPartyDetails.pop(); // Remove the last element

      // Update the form values with the modified ticketDetails array
      setFieldValue("partyDetails", updatedPartyDetails);
    }
  };

  const handleclickback = () => {
    navigate(-1);
  };

 

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...initialValuesAddReport,
      cashFlowDetails: {
        ...datas?.documents?.[0]?.cashFlowDetails,
      },
      ticketDetails: [...datas?.documents?.[0]?.ticketDetails],
      partyDetails: [...datas?.documents?.[0]?.partyDetails],
    },

    validationSchema: validationSchemaAddReport,
    onSubmit: async (values) => {
      try {
        const { cashFlowDetails, ticketDetails, partyDetails } = values;

        const payload = {
          cashFlowDetails,
          ticketDetails, // Wrap ticketDetails in an array
          partyDetails,
        };
    // eslint-disable-next-line
        const response = await mutateAsync({ dataid, payload });
      } catch (error) {
        console.error("Error during login:", error);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = data;
      toast.success(message);

      if (status === 200) {
        resetForm();
        setNumTicketForms(0);
        setNumPartyForms(0);
        navigate("/salereport");
      }
    }

    if (isError) {
      toast.error(error.response.data.message);

      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout();
      }
    }
  }, [isError, isSuccess, data, navigate, error,resetForm, logout]);

  const calculateCash = () => {
    const {
      // notes2000,
      notes500,
      notes200,
      notes100,
      notes50,
      notes20,
      notes10,
      notes5,
      notes2,
      notes1,
    } = values.cashFlowDetails;
    const totalCash =
      // notes2000 * 2000 +
      notes500 * 500 +
      notes200 * 200 +
      notes100 * 100 +
      notes50 * 50 +
      notes20 * 20 +
      notes10 * 10 +
      notes5 * 5 +
      notes2 * 2 +
      notes1 * 1;
    return totalCash ? totalCash : 0;
  };
  const calculateTotalCash = () => {
    const { cash, upi, razorpay } = values.cashFlowDetails;
    const totalCash = cash * 1 + upi * 1 + razorpay * 1;
    return totalCash;
  };

  const calculatePartyCash = (partyIndex) => {
    const {
      // pnotes2000,
      pnotes500,
      pnotes200,
      pnotes100,
      pnotes50,
      pnotes20,
      pnotes10,
      pnotes5,
      pnotes2,
      pnotes1,
    } = values.partyDetails[partyIndex];
    const totalPartyCash =
      // pnotes2000 * 2000 +
      pnotes500 * 500 +
      pnotes200 * 200 +
      pnotes100 * 100 +
      pnotes50 * 50 +
      pnotes20 * 20 +
      pnotes10 * 10 +
      pnotes5 * 5 +
      pnotes2 * 2 +
      pnotes1 * 1;
    return totalPartyCash ? totalPartyCash : 0;
  };

  const calculateTotalPartyCash = (partyIndex) => {
    const { pcash, pupi, prazorpay } = values.partyDetails[partyIndex];
    const totalPartyCash = pcash * 1 + pupi * 1 + prazorpay * 1;
    return totalPartyCash;
  };

  const calculateTotalBand = (ticketindex) => {
    const { ticketOpeningNo, ticketClosingNo } =
      values.ticketDetails[ticketindex];

    const totalband = ticketClosingNo - ticketOpeningNo;
    return totalband ? totalband : 0;
  };

  const calculateUsedTotalBand = (ticketindex) => {
    const { damage, totalBands } = values.ticketDetails[ticketindex];
    const totalusedband = totalBands - damage;
    return totalusedband ? totalusedband : 0;
  };
  useEffect(() => {
    setFieldValue(
      "cashFlowDetails.cash",
      calculateCash()
      // ? calculateCash() : ""
    );

    for (let i = 0; i < numPartyForms; i++) {
      setFieldValue(
        `partyDetails[${i}].pcash`,
        calculatePartyCash(i)
        // ? calculatePartyCash(i) :""
      );
    }

    setFieldValue(
      "cashFlowDetails.totalCash",
      calculateTotalCash() ? calculateTotalCash() : ""
    );
    for (let i = 0; i < numPartyForms; i++) {
      setFieldValue(
        `partyDetails[${i}].ptotalCash`,
        calculateTotalPartyCash(i) ? calculateTotalPartyCash(i) : ""
      );
    }

    for (let i = 0; i < numTicketForms; i++) {
      setFieldValue(
        `ticketDetails[${i}].totalBands`,
        calculateTotalBand(i)
        // ?  calculateTotalBand(i):""
      );
    }

    for (let i = 0; i < numTicketForms; i++) {
      setFieldValue(
        `ticketDetails[${i}].totalUsedBands`,
        calculateUsedTotalBand(i)
        //  ? calculateUsedTotalBand(i):""
      );
    }
        // eslint-disable-next-line
  }, [values]);

  const handleAddTicketForm = () => {
    setNumTicketForms(numTicketForms + 1);
    const newTicket = {
      comboType: "",
      bandColour: "",
      ticketOpeningNo: "",
      ticketClosingNo: "",
      damage: "",
      extendedTicket: "",
      totalComplimentary: "",
      complimentBy: "",
      // annualPass: "",
      totalUsedBands: "",
      totalBands: "",
    };
    setFieldValue(`ticketDetails[${numTicketForms}]`, newTicket);
  };

  const handleTicketChecked = (text) => {
    if (text === "yes") {
      setNumTicketForms(1);
      const newTicket = {
        comboType: "",
        bandColour: "",
        ticketOpeningNo: "",
        ticketClosingNo: "",
        damage: "",
        extendedTicket: "",
        totalComplimentary: "",
        complimentBy: "",
        // annualPass: "",
        totalUsedBands: "",
        totalBands: "",
      };
      setFieldValue(`ticketDetails[${numTicketForms}]`, newTicket);
    }
  };

  const handleRemoveTicketForm = () => {
    if (numTicketForms > 0) {
      setNumTicketForms(numTicketForms - 1);

      const updatedTicketDetails = [...values.ticketDetails];
      updatedTicketDetails.pop(); // Remove the last element

      // Update the form values with the modified ticketDetails array
      setFieldValue("ticketDetails", updatedTicketDetails);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="addreport">
        <div className="addwrapper">
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleclickback}
          >
            <i
              className="fa-solid fa-arrow-left"
              style={{
                color: "var(--secondary-text-color)",
                fontWeight: "800",
                cursor: "pointer",
              }}
            >
              {" "}
            </i>
            <p style={{ fontSize: "18px", fontWeight: 700, margin: "0px" }}>
              Back
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <h2> Edit your DSL Report</h2>
            <i
              className="fa-solid fa-pen"
              style={{ color: "var(--fourth-text-color)", marginTop: "8px" }}
            ></i>
          </div>

          <div className="addreportwrapper">
            <p> Add cash Flow Details</p>
            <form onSubmit={handleSubmit}>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label>Counter Name</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  // eslint-disable-next-line no-undef
                  type="text"
                  placeholder="No of Notes"
                  name="cashFlowDetails.counterName"
                  value={values.cashFlowDetails.counterName}
                  onChange={handleChange}
                  readOnly
                  style={{ cursor: "default" }}
                ></input>
                {errors.cashFlowDetails?.counterName &&
                  touched.cashFlowDetails?.counterName && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.counterName}
                    </div>
                  )}
              </div>

              {/* <div className="formsectionwrapper">
              <div className="labelwraper">
                <label> 2000 Notes</label>
                <label className="dotlabel">:</label>
              </div>
              <input
                type="text"
                placeholder="No of Notes"
                value={values.cashFlowDetails.notes2000}
                name="cashFlowDetails.notes2000"
                onChange={handleChange}
              />
              {errors.cashFlowDetails?.notes2000 &&
                touched.cashFlowDetails?.notes2000 && (
                  <div className="error-message">
                    {errors.cashFlowDetails?.notes2000}
                  </div>
                )}
            </div> */}
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 500 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes500}
                  name="cashFlowDetails.notes500"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes500 &&
                  touched.cashFlowDetails?.notes500 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes500}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 200 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes200}
                  name="cashFlowDetails.notes200"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes200 &&
                  touched.cashFlowDetails?.notes200 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes200}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 100 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes100}
                  name="cashFlowDetails.notes100"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes100 &&
                  touched.cashFlowDetails?.notes100 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes100}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 50 Notes</label>
                  <label className="dotlabel">:</label>
                </div>

                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes50}
                  name="cashFlowDetails.notes50"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes50 &&
                  touched.cashFlowDetails?.notes50 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes50}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 20 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes20}
                  name="cashFlowDetails.notes20"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes20 &&
                  touched.cashFlowDetails?.notes20 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes20}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 10 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes10}
                  name="cashFlowDetails.notes10"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes10 &&
                  touched.cashFlowDetails?.notes10 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes10}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 5 Notes</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.cashFlowDetails.notes5}
                  name="cashFlowDetails.notes5"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes5 &&
                  touched.cashFlowDetails?.notes5 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes5}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 2 Coins</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Coins"
                  value={values.cashFlowDetails.notes2}
                  name="cashFlowDetails.notes2"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes2 &&
                  touched.cashFlowDetails?.notes2 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes2}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 1 Coins</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="No of Coins"
                  value={values.cashFlowDetails.notes1}
                  name="cashFlowDetails.notes1"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.notes1 &&
                  touched.cashFlowDetails?.notes1 && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.notes1}
                    </div>
                  )}
              </div>

              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> Cash</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Cash"
                  value={calculateCash()}
                  name="cashFlowDetails.cash"
                  onChange={handleChange}
                  style={{ cursor: "default" }}
                />
                {errors.cashFlowDetails?.cash &&
                  touched.cashFlowDetails?.cash && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.cash}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label>UPI</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter UPI Amount"
                  value={values.cashFlowDetails.upi}
                  name="cashFlowDetails.upi"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.upi &&
                  touched.cashFlowDetails?.upi && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.upi}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label>Razorpay</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Razorpay Amount"
                  value={values.cashFlowDetails.razorpay}
                  name="cashFlowDetails.razorpay"
                  onChange={handleChange}
                />
                {errors.cashFlowDetails?.razorpay &&
                  touched.cashFlowDetails?.razorpay && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.razorpay}
                    </div>
                  )}
              </div>
              <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> Total</label>
                  <label className="dotlabel">:</label>
                </div>
                <input
                  type="text"
                  placeholder="Enter Total Amount"
                  // value={values.cashFlowDetails.totalCash}
                  value={calculateTotalCash()}
                  name="cashFlowDetails.totalCash"
                  onChange={handleChange}
                  style={{ cursor: "default" }}
                />
                {errors.cashFlowDetails?.totalCash &&
                  touched.cashFlowDetails?.totalCash && (
                    <div className="error-message">
                      {errors.cashFlowDetails?.totalCash}
                    </div>
                  )}
              </div>
            </form>
          </div>
          {/* {data1 === true && ( */}
          <div className="ticketcollector">
            <p style={{ marginBottom: "0px" }}>Band Details</p>

            {numTicketForms === 0 ? (
              <div className="radiowrapperclass">
                <div className="label">
                  <label for="yes_no_radio">
                    Do you Want to add Band details?
                  </label>
                  <label className="dot" style={{ marginRight: "10px" }}>
                    :
                  </label>
                </div>

                <div className="radiowrapper">
                  <p>
                    <input
                      type="checkbox"
                      name="ticket"
                      onClick={() => {
                        handleTicketChecked("yes");
                      }}
                    />
                  </p>
                </div>
              </div>
            ) : (
              <>
                {[...Array(numTicketForms)].map((_, index) => (
                  // <Formik>
                  <form
                    key={index}
                    onSubmit={handleSubmit}
                    style={{ padding: "10px 0px" }}
                  >
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label>Combo Type</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <select
                        name={`ticketDetails[${index}].comboType`}
                        value={values.ticketDetails[index]?.comboType}
                        onChange={handleChange}
                      >
                        <option value="">Select Combo Type</option>
                        {comboType?.map((combo, index) => (
                          <option key={index} value={combo.ticketComboType}>
                            {combo.ticketComboType.charAt(0).toUpperCase() +
                              combo.ticketComboType.slice(1)}{" "}
                          </option>
                        ))}
                      </select>
                      {errors.ticketDetails?.[index]?.comboType &&
                        touched.ticketDetails?.[index]?.comboType && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.comboType}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label>Band Colour</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <select
                        name={`ticketDetails[${index}].bandColour`}
                        value={values.ticketDetails[index]?.bandColour}
                        onChange={handleChange}
                      >
                        <option value="">Select Band Colour</option>
                        {bandColour?.map((band, index) => (
                          <option key={index} value={band.ticketBnadColour}>
                            {band.ticketBnadColour.charAt(0).toUpperCase() +
                              band.ticketBnadColour.slice(1)}{" "}
                          </option>
                        ))}
                      </select>
                      {errors.ticketDetails?.[index]?.bandColour &&
                        touched.ticketDetails?.[index]?.bandColour && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.bandColour}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Band Opening No.</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Band Opening No."
                        name={`ticketDetails[${index}].ticketOpeningNo`}
                        value={values.ticketDetails[index]?.ticketOpeningNo}
                        onChange={handleChange}
                      />
                      {errors.ticketDetails?.[index]?.ticketOpeningNo &&
                        touched.ticketDetails?.[index]?.ticketOpeningNo && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.ticketOpeningNo}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Band Closing No.</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Band Closing No."
                        name={`ticketDetails[${index}].ticketClosingNo`}
                        value={values.ticketDetails[index]?.ticketClosingNo}
                        onChange={handleChange}
                      />
                      {errors.ticketDetails?.[index]?.ticketClosingNo &&
                        touched.ticketDetails?.[index]?.ticketClosingNo && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.ticketClosingNo}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Damage</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Total No. of Damage"
                        name={`ticketDetails[${index}].damage`}
                        value={values.ticketDetails[index]?.damage}
                        onChange={handleChange}
                      />
                      {errors.ticketDetails?.[index]?.damage &&
                        touched.ticketDetails?.[index]?.damage && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.damage}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Extended Ticket</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Number"
                        name={`ticketDetails[${index}].extendedTicket`}
                        value={values.ticketDetails[index]?.extendedTicket}
                        onChange={handleChange}
                      />
                      {errors.ticketDetails?.[index]?.extendedTicket &&
                        touched.ticketDetails?.[index]?.extendedTicket && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.extendedTicket}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Compliment</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter Compliment"
                        name={`ticketDetails[${index}].totalComplimentary`}
                        value={values.ticketDetails[index]?.totalComplimentary}
                        onChange={handleChange}
                      />
                      {errors.ticketDetails?.[index]?.totalComplimentary &&
                        touched.ticketDetails?.[index]?.totalComplimentary && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.totalComplimentary}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> ComplimentBy</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter Compliment By"
                        name={`ticketDetails[${index}].complimentBy`}
                        value={values.ticketDetails[index]?.complimentBy}
                        onChange={handleChange}
                      />
                      {errors.ticketDetails?.[index]?.complimentBy &&
                        touched.ticketDetails?.[index]?.complimentBy && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.complimentBy}
                          </div>
                        )}
                    </div>

                    {/* <div className="formsectionwrapper">
                  <div className="labelwraper">
                    <label> Anual Pass</label>
                    <label className="dotlabel">:</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Total No. Of Anual pass"
                    name={`ticketDetails[${index}].annualPass`}
                    value={values.ticketDetails[index]?.annualPass}
                    onChange={handleChange}
                  />
                  {errors.ticketDetails?.[index]?.annualPass &&
                    touched.ticketDetails?.[index]?.annualPass && (
                      <div className="error-message">
                        {errors.ticketDetails?.[index]?.annualPass}
                      </div>
                    )}
                </div> */}
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Total Used Bands</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Number"
                        name={`ticketDetails[${index}].totalUsedBands`}
                        // value={values.ticketDetails[index]?.totalUsedBands}
                        value={calculateUsedTotalBand(index)}
                        onChange={handleChange}
                        style={{ cursor: "default" }}
                      />
                      {errors.ticketDetails?.[index]?.totalUsedBands &&
                        touched.ticketDetails?.[index]?.totalUsedBands && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.totalUsedBands}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Total Bands</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter Number"
                        name={`ticketDetails[${index}].totalBands`}
                        // value={values.ticketDetails[index]?.totalBands}
                        value={calculateTotalBand(index)}
                        onChange={handleChange}
                        style={{ cursor: "default" }}
                      />
                      {errors.ticketDetails?.[index]?.totalBands &&
                        touched.ticketDetails?.[index]?.totalBands && (
                          <div className="error-message">
                            {errors.ticketDetails?.[index]?.totalBands}
                          </div>
                        )}
                    </div>
                    {index > -1 && index < numTicketForms - 1 && (
                      <div className="horizontaldivider"></div>
                    )}
                  </form>
                ))}

                <div className="addticketdetails">
                  {numTicketForms > 0 && (
                    <button className="btt" onClick={handleRemoveTicketForm}>
                      Sub
                    </button>
                  )}
                  <button className="btt" onClick={handleAddTicketForm}>
                    Add
                  </button>
                </div>
              </>
            )}
            <p style={{ marginBottom: "0px" }}> Party Details</p>

            {numPartyForms === 0 ? (
              <div className="radiowrapperclass">
                <div className="label">
                  <label for="yes_no_radio">
                    Do you Want to add Party details?
                  </label>
                  <label className="dot" style={{ marginRight: "10px" }}>
                    :
                  </label>
                </div>

                <div className="radiowrapper">
                  <p>
                    <input
                      type="checkbox"
                      name="party"
                      onClick={() => {
                        handleChecked("yes");
                      }}
                    />
                  </p>
                </div>
              </div>
            ) : (
              <>
                {[...Array(numPartyForms)].map((_, indexes) => (
                  <form
                    key={indexes}
                    onSubmit={handleSubmit}
                    style={{ padding: "10px 0px" }}
                  >
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Party Name</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter Party Name"
                        value={values.partyDetails[indexes]?.partyName}
                        name={`partyDetails[${indexes}].partyName`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.partyName &&
                        touched.partyDetails?.[indexes]?.partyName && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.partyName}
                          </div>
                        )}
                    </div>
                    {/* <div className="formsectionwrapper">
                <div className="labelwraper">
                  <label> 2000 Notes</label>
                  <label className="dotlabel">:</label>
                </div>

                <input
                  type="text"
                  placeholder="No of Notes"
                  value={values.partyDetails[indexes]?.pnotes2000}
                  name={`partyDetails[${indexes}].pnotes2000`}
                  onChange={handleChange}
                />
                {errors.partyDetails?.[indexes]?.pnotes2000 &&
                  touched.partyDetails?.[indexes]?.pnotes2000 && (
                    <div className="error-message">
                      {errors.partyDetails?.[indexes]?.pnotes2000}
                    </div>
                  )}
              </div> */}
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 500 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>
                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes500}
                        name={`partyDetails[${indexes}].pnotes500`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes500 &&
                        touched.partyDetails?.[indexes]?.pnotes500 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes500}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 200 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes200}
                        name={`partyDetails[${indexes}].pnotes200`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes200 &&
                        touched.partyDetails?.[indexes]?.pnotes200 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes200}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 100 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes100}
                        name={`partyDetails[${indexes}].pnotes100`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes100 &&
                        touched.partyDetails?.[indexes]?.pnotes100 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes100}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 50 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes50}
                        name={`partyDetails[${indexes}].pnotes50`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes50 &&
                        touched.partyDetails?.[indexes]?.pnotes50 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes50}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 20 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes20}
                        name={`partyDetails[${indexes}].pnotes20`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes20 &&
                        touched.partyDetails?.[indexes]?.pnotes20 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes20}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 10 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes10}
                        name={`partyDetails[${indexes}].pnotes10`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes10 &&
                        touched.partyDetails?.[indexes]?.pnotes10 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes10}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 5 Notes</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Notes"
                        value={values.partyDetails[indexes]?.pnotes5}
                        name={`partyDetails[${indexes}].pnotes5`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes5 &&
                        touched.partyDetails?.[indexes]?.pnotes5 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes5}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 2 Coins</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Coins"
                        value={values.partyDetails[indexes]?.pnotes2}
                        name={`partyDetails[${indexes}].pnotes2`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes2 &&
                        touched.partyDetails?.[indexes]?.pnotes2 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes2}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> 1 coins</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="No of Coins"
                        value={values.partyDetails[indexes]?.pnotes1}
                        name={`partyDetails[${indexes}].pnotes1`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pnotes1 &&
                        touched.partyDetails?.[indexes]?.pnotes1 && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pnotes1}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Cash</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter Cash"
                        // value={values.partyDetails[indexes]?.pcash}
                        name={`partyDetails[${indexes}].pcash`}
                        value={calculatePartyCash(indexes)}
                        style={{ cursor: "default" }}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pcash &&
                        touched.partyDetails?.[indexes]?.pcash && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pcash}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> UPI</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter UPI Amount"
                        value={values.partyDetails[indexes]?.pupi}
                        name={`partyDetails[${indexes}].pupi`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.pupi &&
                        touched.partyDetails?.[indexes]?.pupi && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.pupi}
                          </div>
                        )}
                    </div>

                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Razorpay</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter UPI Amount"
                        value={values.partyDetails[indexes]?.prazorpay}
                        name={`partyDetails[${indexes}].prazorpay`}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.prazorpay &&
                        touched.partyDetails?.[indexes]?.prazorpay && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.prazorpay}
                          </div>
                        )}
                    </div>
                    <div className="formsectionwrapper">
                      <div className="labelwraper">
                        <label> Total</label>
                        <label className="dotlabel">:</label>
                      </div>

                      <input
                        type="text"
                        placeholder="Enter Total Amount"
                        // value={values.partyDetails[indexes]?.ptotalCash}
                        name={`partyDetails[${indexes}].ptotalCash`}
                        value={calculateTotalPartyCash(indexes)}
                        style={{ cursor: "default" }}
                        onChange={handleChange}
                      />
                      {errors.partyDetails?.[indexes]?.ptotalCash &&
                        touched.partyDetails?.[indexes]?.ptotalCash && (
                          <div className="error-message">
                            {errors.partyDetails?.[indexes]?.ptotalCash}
                          </div>
                        )}
                    </div>
                    {indexes > -1 && indexes < numPartyForms - 1 && (
                      <div className="horizontaldivider"></div>
                    )}
                  </form>
                ))}

                <div className="addticketdetails">
                  {numPartyForms > 0 && (
                    <button className="btt" onClick={handleRemovePartyForm}>
                      Sub
                    </button>
                  )}
                  <button className="btt" onClick={handleAddPartyForm}>
                    Add
                  </button>
                </div>
              </>
            )}
          </div>
          {/* )} */}
          <button className="btt" type="submit" onClick={handleSubmit}>
            {" "}
            {isLoading ? (
              <BeatLoader size={8} color={"#ffffff"} />
            ) : (
              "Submit Now"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTicketCollector;
