import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const iframe = document.getElementById("looker-studio-iframe");

    const handleLoad = () => {
      setLoading(false);
    };

    iframe.addEventListener("load", handleLoad);

    return () => {
      iframe.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10,
          }}
        >
          <ClipLoader size={100} />
        </div>
      )}
      <iframe
        id="looker-studio-iframe"
        title="Looker Studio Report"
        width="100%"
        height="100%"
        src={process.env.LOCKER_DASHBOARD_LINK || 'https://lookerstudio.google.com/embed/reporting/682cabb0-99d7-4e66-8392-28f50343269f/page/p_o4v4hupfid'}
        style={{ border: "none" }}
        allowFullScreen
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
};

export default Dashboard;
