import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./forgetpassword.css";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { initialotp, otpSchema } from "../../validations";
import { useVerifyOtp } from "../../hooks/forgetpassword";

const ForgetPassword2 = () => {
  const phone = localStorage.getItem("phoneNumber");
  const navigate = useNavigate();
  const { mutateAsync, isLoading, isSuccess, isError, data, error } =useVerifyOtp();
  

  const {
    errors,
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: {
      ...initialotp,
      phone: phone,
      purpose: "forgetpassword",
    },
    validationSchema: otpSchema,
    onSubmit: async (values) => {
      try {
        const response = await mutateAsync(values);
        console.log("🚀 ~ onSubmit: ~ response?.data:", response);

        // Set user ID in local storage
      } catch (error) {
        console.error("Error during phone:", error);
        // toast.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === 200) {
        toast.success(data?.message);
        // localStorage.removeItem('phoneNumber'); // Set phone number in localStorage
        localStorage.setItem("access_token", data?.data);

        navigate("/forgetpassword3");

        resetForm();
      } else {
        toast.error("otp Invalid ");
      }
    }

    if (isError) {
      toast.error("Something went wrong");
      if (error?.response?.data?.status === 401) {
        navigate("/");
      }
    }
  }, [isError, isSuccess, data, navigate, resetForm,error]);

  return (
    <div>
      <div className="forgetpage">
        <div className="forget">
          <form onSubmit={handleSubmit}>
            <div className="forminput">
              <label> Enter otp*</label>
              <input
                type="text"
                placeholder="Enter your 6 digit otp "
                name="otp"
                value={values.otp}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.otp && errors.otp && (
                <div className="error-messages">{errors.otp}</div>
              )}
            </div>
            <button className="btt" type="submit" disabled={isLoading}>
              {isLoading ? <BeatLoader size={8} color={"#ffffff"} /> : "Next"}
            </button>
          </form>

          <Link to="/" style={{ textDecoration: "none" }}>
            <p className="forgetback">Back to login page</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword2;
