import React from "react";
import "./cashflow.css";

const CashFlow = ({ handleCloseModal, data }) => {
  // const data = useAdminReport();

  const calculateTotalNotes = (denomination) => {
    let total = 0;
    // Iterate through the data array
    data?.data.forEach((item) => {
      // Check if the item has documents
      if (item.documents) {
        // Iterate through documents array for each item
        item.documents.forEach((doc) => {
          // Check if cashFlowDetails exist and has the denomination
          if (doc.cashFlowDetails && doc.cashFlowDetails[denomination]) {
            // Add notes from each document to the total
            total += parseInt(doc.cashFlowDetails[denomination]);
          }
        });
      }
    });
    return total;
  };

  return (
    <div>
      <div className="modales" tabIndex="-1">
        <div className="modal-dialogs">
          <div className="modal-contents">
            <div className="modal-headers">
              <h5 className="modal-title">CashFlow</h5>
              <button
                type="button"
                className="cancelbtt"
                onClick={handleCloseModal}
              >
                <i
                  className="fa-solid fa-xmark fa-xl"
                  style={{ color: "var(--secondary-text-color)" ,cursor:'pointer'}}
                ></i>
              </button>
            </div>
            <div className="modal-body">
              <table>
                <tr>
                  <th>Currency</th>
                  <th>Notes</th>
                </tr>
                {/* <tr>
                  <td>2000 Notes</td>
                  <td>{calculateTotalNotes("notes2000")}</td>
                </tr> */}
                <tr>
                  <td>500 Notes</td>
                  <td>{calculateTotalNotes("notes500")} </td>
                </tr>
                <tr>
                  <td>200 Notes</td>
                  <td>{calculateTotalNotes("notes200")}</td>
                </tr>
                <tr>
                  <td>100 Notes</td>
                  <td>{calculateTotalNotes("notes100")}</td>
                </tr>
                <tr>
                  <td>50 Notes</td>
                  <td>{calculateTotalNotes("notes50")}</td>
                </tr>
                <tr>
                  <td>20 Notes</td>
                  <td>{calculateTotalNotes("notes20")}</td>
                </tr>
                <tr>
                  <td>10 Notes</td>
                  <td>{calculateTotalNotes("notes10")}</td>
                </tr>
                <tr>
                  <td>5 Notes</td>
                  <td>{calculateTotalNotes("notes5")}</td>
                </tr>
                <tr>
                  <td>2 Coins</td>
                  <td>{calculateTotalNotes("notes2")}</td>
                </tr>
                <tr>
                  <td>1 Coins</td>
                  <td>{calculateTotalNotes("notes1")}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashFlow;
