import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://eod.soluperts.com/api/",
  // baseURL: process.env.REACT_APP_BASE_URL ||'http://localhost:5000/',

  headers: {
    "Content-Type": "application/json",
    accesstoken: localStorage.getItem("access_token"),
    // "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status === 451 &&
      error.response.data.error === "Token prefix expired"
    ) {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
