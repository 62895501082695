import { useMutation } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

const fetchUploadFile = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.UPLOAD_FILE,
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useUploadFile = () => {
  return useMutation({
    queryKey: ["useUploadFile"],
    mutationFn: (params) => fetchUploadFile(params),
  });
};

export { useUploadFile };
