import { useMutation, useQuery } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

const fetchCounterName = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.COUNTERNAME}/?pageSize=0`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useCounterName = () => {
  return useQuery({
    queryKey: ["useCounterName"],
    queryFn: (params) => fetchCounterName(params),
  });
};

// VERIFY COUNTER NAME

const fetchVerifyCounterName = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.VERIFYCOUNTERNAME,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useVerifyCounterName = () => {
  return useMutation({
    queryKey: ["useVerifyCounterName"],
    mutationFn: (params) => fetchVerifyCounterName(params),
  });
};

const fetchCounter = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.FETCH_COUNTER,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useCounter = () => {
  return useMutation({
    queryKey: ["useCounter"],
    mutationFn: (params) => fetchCounter(params),
  });
};

export { useCounterName, useVerifyCounterName, useCounter };
