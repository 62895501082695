import React from "react";
import axiosInstance from "../../../services/axiosInstance";
import ENDPOINTS from "../../../services/endPoints";
import { toast } from "react-toastify";

const EmployeeCard = ({ items, onDeleteSuccess, onEditClick }) => {
  const handleDelete = async (itemId, name) => {
    try {
      //give alert
      if (window.confirm(`Are you sure you want to delete ${name}`)) {
        const { data } = await axiosInstance({
          method: "delete",
          url: `${ENDPOINTS.EMPLOYEE}${itemId}`, // Corrected the URL interpolation
          headers: { "Content-Type": "application/json" },
        });
        toast.success("Employee deleted successfully");
        onDeleteSuccess();
        return data;
      }
    } catch (error) {
      console.error("Error deleting counter item:", error);

      throw error; // Rethrow the error for error handling in higher components if needed
    }
  };
  return (
    <div>
      <div className="counteritemwrapper">
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <i
            className="fa-solid fa-file-pen"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   handleEdit(items?._id);
            // }}
            onClick={() => onEditClick(items)} // Trigger edit click handler
          ></i>
          <i
            className="fa-solid fa-trash"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDelete(items?._id, items?.name);
            }}
          ></i>
        </div>
        <div className="counteritemcontent">
          <p>
            Name: <span>{items?.name}</span>
          </p>
          <p>
            UserName: <span>{items?.userName}</span>
          </p>
          <p>
            Phone Number: <span>{items?.phone}</span>
          </p>
          <p>
            User Type: <span>{items?.employeetype}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
