import React from "react";
import axiosInstance from "../../../services/axiosInstance";
import ENDPOINTS from "../../../services/endPoints";
import { toast } from "react-toastify";

const CounterItem = ({ countername, countertype, id, onDeleteSuccess }) => {
  const handleDelete = async (itemId) => {
    try {
      const { data } = await axiosInstance({
        method: "delete",
        url: `${ENDPOINTS.DELETE_COUNTER}${itemId}`, // Corrected the URL interpolation
        headers: { "Content-Type": "application/json" },
      });
      toast.success("Counter item deleted successfully");
      onDeleteSuccess();
      return data;
    } catch (error) {
      console.error("Error deleting counter item:", error);

      throw error; // Rethrow the error for error handling in higher components if needed
    }
  };

  return (
    <div>
      <div className="counteritemwrapper">
        <i
          className="fa-solid fa-trash"
          style={{ display: "flex", justifyContent: "flex-end",cursor:'pointer' }}
          onClick={() => {
            handleDelete(id);
          }}
        ></i>
        <div className="counteritemcontent">
          <p>
            Counter Name: <span>{countername}</span>
          </p>
          <p>
            Counter Type: <span>{countertype}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CounterItem;
