import { useMutation } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

const adminReportData = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.REPORT,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useAdminReport = () => {
  return useMutation({
    queryKey: ["useAdminReport"],
    mutationFn: (params) => adminReportData(params),
  });
};

// party report

const adminPartyReportData = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.PARTYREPORT,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const usePartyAdminReport = () => {
  return useMutation({
    queryKey: ["usePartyAdminReport"],
    mutationFn: (params) => adminPartyReportData(params),
  });
};

export { useAdminReport, usePartyAdminReport };
