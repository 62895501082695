import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import SaleReport from "./saleReport/SaleReport";

const Admin = () => {
  return (
    <div>
      <div
        className="dashboard"
        style={{ background: "var(--primary-background)", minHeight: "100vh" }}
      >
        <Navbar />
        <SaleReport />
      </div>
    </div>
  );
};

export default Admin;
