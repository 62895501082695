import { useMutation } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

// post party details

const fetchPartyDetails = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.ADDPARTYDETAILS,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const usePartyDetails = () => {
  return useMutation({
    queryKey: ["usePartyDetails"],
    mutationFn: (params) => fetchPartyDetails(params),
  });
};

const fetchGuestList = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.UPLOAD_GUEST_LIST,
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useGuestListFile = () => {
  return useMutation({
    queryKey: ["useGuestListFile"],
    mutationFn: (params) => fetchGuestList(params),
  });
};

// get document by id

const getPartyDetailsById = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `${ENDPOINTS.PARTY_DETAIL_BYID}${params}`,
      // data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const usePartyDetailsBYId = () => {
  return useMutation({
    queryKey: ["usePartyDetailsBYId"],
    mutationFn: (params) => getPartyDetailsById(params),
  });
};

// edit party details

const editPartyDetails = async (preDataid, payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.EDIT_PARTY_DETAILS}${preDataid}`,
      data: payload,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useUpdatePartyDetails = () => {
  return useMutation({
    queryKey: ["useUpdatePartyDetails"],
    mutationFn: ({ preDataid, payload }) =>
      editPartyDetails(preDataid, payload),
  });
};

export {
  usePartyDetails,
  useGuestListFile,
  usePartyDetailsBYId,
  useUpdatePartyDetails,
};
