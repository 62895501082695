const ENDPOINTS = {
  ADDEMPLOYEE: "api/v1/addemployee/addemployee",
  LOGIN: "api/v1/addemployee/login",
  EMPLOYEE_list: "api/v1/addemployee/list/",
  EMPLOYEE: "api/v1/addemployee/",
  PROFILE: "api/v1/addemployee/",
  ADDREPORT: "api/v1/addreport/",
  ADDREPORTDETAILS: "api/v1/addreport/",
  ADDPARTYDETAILS: "api/v1/partydetails/",
  REPORT: "api/v1/report/",
  FORGETPASSWORD: "api/v1/createotp/",
  VERIFYOTP: "api/v1/createotp/verifyotp",
  CHANGEPASSWORD: "api/v1/createotp/changepassword",
  COUNTERNAME: "api/v1/employeetype/",
  VERIFYCOUNTERNAME: "api/v1/employeetype/verify",
  NON_TICKET_COLLECTOR: "api/v1/addreport/nonticket",
  PARTYREPORT: "api/v1/reportparty/",
  UPLOAD_FILE: "api/v1/uploadfile/",
  PROFILE_DETAILS: "api/v1/profileimg/",
  PROFILE_UPLOAD_IMG: "api/v1/profileimg/",
  FETCH_COUNTER: "api/v1/employeetype/",
  DELETE_COUNTER: "api/v1/employeetype/",
  BAND_DETAILS: "api/v1/bandcolour/",
  FETCH_BAND: "api/v1/bandcolour/",
  DELETE_BAND: "api/v1/bandcolour/",
  EDIT_DSLNONREPORT: "api/v1/addreport/nonticket/",
  EDIT_DSLREPORT: "api/v1/addreport/",
  UPLOAD_GUEST_LIST: "api/v1/partydetails/guestlist/",
  PARTY_DETAIL_BYID: "api/v1/partydetails/",
  EDIT_PARTY_DETAILS: "api/v1/partydetails/",
  COMBO_TYPE: "api/v1/comboType/",

};
export default ENDPOINTS;
