import React, { useEffect, useState } from "react";
import BandColourItem from "./BandColourItem";
import { BeatLoader } from "react-spinners";
import "./bandcolour.css";
import { useBandDetails, useBandColour } from "../../../hooks/bandcolour";
import { toast } from "react-toastify";
import { initialBandColour, bandColourSchema } from "../../../validations";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../utls/Context";

const BandColour = () => {
  const navigate = useNavigate();
  const {logout}= useStateContext()
  const [refreshBandDetails, setRefreshBandDetails] = useState(false);

  const { data: banddetails, refetch: refetchBandDetails } = useBandDetails();
  const {
    mutateAsync: muteaddbandcolour,
    isLoading,
    isSuccess,
    isError,
    data: addbandcolour,
    error,
  } = useBandColour();

  

  useEffect(() => {
    if (refreshBandDetails) {
      // Reload data logic here
      // For example, refetch data using the useCounterName hook
      refetchBandDetails();
      setRefreshBandDetails(false);
    }
  }, [refreshBandDetails, refetchBandDetails]);

  const {
    resetForm,
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: initialBandColour,
    validationSchema: bandColourSchema,
    onSubmit: async (values) => {
      try {
       await muteaddbandcolour(values);
      } catch (error) {
        console.error("Error during conter Addition:", error);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = addbandcolour;
      toast.success(message);

      if (status === 200) {
        resetForm();
        refetchBandDetails();
      }
    }

    if (isError) {
      toast.error(error.response.data.message);
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout()
      }
    }
  }, [isError, isSuccess,addbandcolour, error, resetForm,navigate, refetchBandDetails, logout]);
  return (
    <div>
      <div className="uploaddetails">
        <div className="uploadwrappers">
          <div className="heading">
            <p>Add Band Colour</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="fileinput">
              <div className="label">
                <label>Band Colour Name</label>
                <label style={{ marginRight: "10px" }}>:</label>
              </div>
              <div className="inputtype">
                <input
                  type="text"
                  placeholder="Enter Band Colour Name"
                  name="ticketBnadColour"
                  value={values.ticketBnadColour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.ticketBnadColour && touched.ticketBnadColour && (
                  <div className="error-message">{errors.ticketBnadColour}</div>
                )}
              </div>
            </div>

            <div className="button">
              <button type="submit" className="btt">
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Submit Now"
                )}
              </button>
            </div>
          </form>
        </div>

        <div className="uploadwrapper">
          <div className="contercontent">
            <div className="heading">
              <p>Band Colour:-</p>
            </div>
          </div>

          <div className="counteritem">
            {banddetails?.data?.data?.map((item, index) => {
              return (
                <div className="colcounter" key={index}>
                  <BandColourItem
                    bandcolour={item?.ticketBnadColour}
                    id={item?._id}
                    onDeleteSuccess={() => setRefreshBandDetails(true)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BandColour;
