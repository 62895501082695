import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/addemployee";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";
import AOS from "aos"; // Import AOS library
import "aos/dist/aos.css"; // Import AOS styles
import { LoginSchema, initialValuesLogin } from "../../validations/index";
import { eyeOpen, eyeClose } from "../../utls";

const LoginForm = () => {
  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const employeetypes = localStorage.getItem("employeetype");
  const [Active, setActive] = useState(false);

  const { mutateAsync, isLoading, isSuccess, isError, data,error } = useLogin();

  

  const {
    errors,
    resetForm,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialValuesLogin,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync({
          userName: values.userName,
          password: values.password,
        });
        // Set user ID in local storage
      } catch (error) {
        console.error("Error during login:", error);
        // toast.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status, data: responseData } = data;

      if (status === 200) {
        const { employeetype } = responseData.data;
        toast.success("Login successfully!");
        localStorage.setItem("access_token", responseData?.accessToken);
        localStorage.setItem("user_id", responseData?.data?.id);
        localStorage.setItem("employeetype", employeetype);
        sessionStorage.setItem("reloadCount", String(1));

        if (employeetype === "POS Employee") {
          navigate("/index");
        } else if (employeetype === "Opertional Manager") {
          navigate("/opertionalmanager");
        } else if (employeetype === "Financial Manager") {
          navigate("/financialmanager");
        } else if (employeetype === "Admin") {
          navigate("/dashboard");
        } else {
          console.error("Unsupported employee type:", employeetype);
        }
        resetForm();
      } else {
        toast.error("Password or email not found");
      }
    }

    if (isError) {
      toast.error("Something went wrong");
    }
  }, [isError, isSuccess, data, navigate, error,resetForm]);
  useEffect(() => {
    if (access_token) {
      if (employeetypes === "POS Employee") {
        navigate("/index");
      } else if (employeetypes === "Opertional Manager") {
        navigate("/opertionalmanager");
      } else if (employeetypes === "Financial Manager") {
        navigate("/financialmanager");
      } else if (employeetypes === "Admin") {
        navigate("/dashboard");
      }
    }
  }, [access_token,employeetypes,navigate]);
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div className="loginformwraper">
        <h2 className="title">Welcome Back 👋</h2>
        <p className="subtitle">
          We happy to see you again. To use your account, you should log in
          first.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <label> UserName*</label>
            <input
              type="text"
              placeholder="Enter your Username"
              name="userName"
              value={values.userName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.userName && errors.userName && (
              <div className="error-messages">{errors.userName}</div>
            )}
          </div>
          <div className="input-field">
            <label> Password*</label>
            <div className="input-container">
              <input
                type={Active ? "text" : "password"}
                placeholder="Enter your Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <span>
                <span onClick={() => setActive(!Active)}>
                  {Active ? eyeOpen : eyeClose}
                </span>
              </span>
            </div>
            {touched.password && errors.password && (
              <div className="error-messages">{errors.password}</div>
            )}
          </div>

          <Link to="/forgetpassword1" style={{ textDecoration: "none" }}>
            <p>Forgot Password?</p>
          </Link>
          <div>
            <button className="btt" type="submit" disabled={isLoading}>
              {" "}
              {isLoading ? <BeatLoader size={8} color={"#ffffff"} /> : "Login"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
