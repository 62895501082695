import React, { useState, useEffect } from "react";
import "./countername.css";
import { useCounterName, useVerifyCounterName } from "../../hooks/counter";
import { initialCounterName, counterNameSchema } from "../../validations";
import { useFormik } from "formik";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../utls/Context";

const CounterName = ({ handleClose }) => {
  const navigate = useNavigate();
  const data = useCounterName();
  const [counterNames, setcounterNames] = useState([]);
  const {logout}= useStateContext()

  const {
    mutateAsync,
    isLoading,
    isSuccess,
    isError,
    data: counterNamedata,
    error,
  } = useVerifyCounterName();

  useEffect(() => {
    setcounterNames(data?.data?.data?.data);
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      const { status, data: responseData } = counterNamedata;

      if (status === 200) {
        handleClose();

        if (responseData?.counterType === "ticketcollector") {
          navigate("/addreport", { state: responseData?.counterName });
        } else {
          navigate("/addreportnonticket", { state: responseData?.counterName });
        }
        resetForm();
      } else {
        // toast.error("Password or email not found");
      }
    }

    if (isError) {
      //   toast.error("Something went wrong");
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout();
      }
    }
    // eslint-disable-next-line
  }, [isError, isSuccess, counterNamedata, navigate, logout]);

  const { resetForm, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialCounterName,
      validationSchema: counterNameSchema,
      onSubmit: async (values) => {
        try {
          // eslint-disable-next-line
          const response = await mutateAsync(values);
          // toast.success("Report added successfully!");
        } catch (error) {
          console.error("Error during login:", error);
          // toast.error("Something went wrong");
        }
      },
    });

  return (
    <div>
      <div className="modals" tabIndex="-1">
        <div className="modals-dialog">
          <div className="modals-content">
            <div className="modals-header">
              <h5 className="modals-title">Choose Counter Name</h5>
              <button
                type="button"
                className="cancelbtts"
                onClick={handleClose}
              >
                <i
                  className="fa-solid fa-xmark fa-xl"
                  style={{ color: "var(--secondary-text-color)" }}
                ></i>
              </button>
            </div>
            <div className="modals-body">
              <div className="profiledetails">
                <form onSubmit={handleSubmit}>
                  <div className="formsectionwrapper">
                    <div className="labelwraper">
                      <label>Counter Name</label>
                    </div>
                    <select
                      // eslint-disable-next-line no-undef
                      name="counterName"
                      onChange={handleChange}
                    >
                      <option value="">Select Counter Name</option>
                      {counterNames?.map((counter, index) => (
                        <option key={index} value={counter.counterName}>
                          {counter.counterName}
                        </option>
                      ))}
                    </select>
                    {errors.counterName && touched.counterName && (
                      <div className="error-message">{errors.counterName}</div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="modals-footer">
              <button
                type="button"
                className="btns btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleClose}
                style={{ background: "var( --button-color-second)" }}
              >
                Close
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btns btn-primary"
                style={{ background: "var( --button-color)" }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterName;
