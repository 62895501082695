import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfileView from "../profile/ProfileView";
import "./Navbar.css";
import { useStateContext } from "../../utls/Context";

const Navbar = () => {
  const [showList, setShowList] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const employeeType = localStorage.getItem("employeetype");
  const accessToken = localStorage.getItem("access_token");
  const {logout}= useStateContext()

  const handleToggleList = () => {
    setShowList(!showList);
  };
  const closeToggleList = () => {
    setShowList(false);
  };

  const handleToggleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const handleLogout = () => {
    navigate("/");
    logout()
  };

  // Render different links based on employee type
  const renderLinks = () => {
    switch (employeeType) {
      case "Financial Manager":
        return (
          <>
            <li className="navitems">
              <Link
                className={`navlink ${
                  (location.pathname === "/financialmanager" || location.pathname.startsWith("/salereport") )? "active" : ""
                }`}
                to="/financialmanager"
                onClick={closeToggleList}
              >
                Sale Report
              </Link>
            </li>
          </>
        );
      case "Opertional Manager":
        return (
          <>
            <li className="navitems">
              <Link
                className={`navlink ${
                  (location.pathname === "/opertionalmanager"  || location.pathname.startsWith("/salereport"))? "active" : ""
                }`}
                to="/opertionalmanager"
                onClick={closeToggleList}
              >
                Sale Report
              </Link>
            </li>
            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname === "/partydetails" ? "active" : ""
                }`}
                to="/partydetails"
                onClick={closeToggleList}
              >
                Party DSR
              </Link>
            </li>
          </>
        );
      case "Admin":
        return (
          <>
            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname.startsWith("/salereport") ? "active" : ""
                }`}
                to="/salereport"
                onClick={closeToggleList}
              >
                Sale Report
              </Link>
            </li>
            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname.startsWith("/employee") ? "active" : ""
                }`}
                to="/employee"
                onClick={closeToggleList}
              >
                Employee
              </Link>
            </li>
            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname === "/uploadfile" ? "active" : ""
                }`}
                aria-current="page"
                to="/uploadfile"
                onClick={closeToggleList}
              >
                Upload File
              </Link>
            </li>

            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname === "/partydetails" ? "active" : ""
                }`}
                aria-current="page"
                to="/partydetails"
                onClick={closeToggleList}
              >
                Party Details
              </Link>
            </li>

            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname.startsWith("/counterdetails") ? "active" : ""
                }`}
                aria-current="page"
                to="/counterdetails"
                onClick={closeToggleList}
              >
                Counter
              </Link>
            </li>
            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname === "/bandcolour" ? "active" : ""
                }`}
                aria-current="page"
                to="/bandcolour"
                onClick={closeToggleList}
              >
                Band Colour
              </Link>
            </li>

            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname === "/combotype" ? "active" : ""
                }`}
                aria-current="page"
                to="/combotype"
                onClick={closeToggleList}
              >
                Combo Type
              </Link>
            </li>
          </>
        );
      case "POS Employee":
        return (
          <>
            <li className="navitems">
              <Link
                className={`navlink ${
                  location.pathname === "/index" ? "active" : ""
                }`}
                to="/index"
                onClick={closeToggleList}
              >
                Sale Report
              </Link>
            </li>
          </>
        );

      default:
        return (
          <>
            {/* <li className="navitems">
              <Link className={`navlink ${location.pathname === '/salereport' ? 'active' : ''}`} to="/salereport">
                Sale Report
              </Link>
            </li> */}
          </>
        );
    }
  };

  const getLogoLink = () => {
    switch (employeeType) {
      case "Financial Manager":
        return "/financialmanager";
      case "Operational Manager":
        return "/opertionalmanager";
      case "Admin":
        return "/dashboard";
      case "POS Employee":
        return "/index";
      default:
        return "";
    }
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navcontainer">
          <Link className="navbar-brand" to={getLogoLink()}>
            <img src="assest/logo.svg" alt="" />
          </Link>
          <div className={`navlist ${showList ? "show" : ""}`}>
            <ul>{renderLinks()}</ul>
          </div>
          <div className="profile">
            <div style={{ display: "flex", alignItems: "end", gap: "5px" }}>
              {/* <img src='assest/pintu.jpeg' alt="" />
            <i className="fa-solid fa-angle-down fa-lg" style={{marginBottom:'10px'}} onClick={handleToggleProfileModal}></i> */}
              {accessToken && (
                <button className="log" onClick={handleLogout}>
                  Logout
                </button>
              )}
            </div>
            {accessToken && (
              <button
                className="navbarbtt"
                type="button"
                onClick={handleToggleList}
              >
                <i className="fa-solid fa-bars fa-xl"></i>
              </button>
            )}
          </div>
        </div>
      </nav>
      {showProfileModal && (
        <ProfileView handleClose={handleToggleProfileModal} />
      )}
    </div>
  );
};

export default Navbar;
