import { useMutation } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

const fetchForgetPassword = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.FORGETPASSWORD,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during signup:", error);
    throw error;
  }
};

const useForgetPassword = () => {
  return useMutation({
    queryKey: ["useForgetPassword"],
    mutationFn: (params) => fetchForgetPassword(params),
  });
};

const fetchVerifyOtp = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.VERIFYOTP,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during signup:", error);
    throw error;
  }
};

const useVerifyOtp = () => {
  return useMutation({
    queryKey: ["useVerifyOtp"],
    mutationFn: (params) => fetchVerifyOtp(params),
  });
};

const fetchChangePassword = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.CHANGEPASSWORD,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during signup:", error);
    throw error;
  }
};

const useChangePassword = () => {
  return useMutation({
    queryKey: ["useChangePassword"],
    mutationFn: (params) => fetchChangePassword(params),
  });
};

export { useForgetPassword, useVerifyOtp, useChangePassword };
