import React, { createContext, useContext } from "react";
const StateContext = createContext();

export const ContextProvider = ({ children }) => {


    const logout = () => {
        localStorage.removeItem("access_token");
    localStorage.removeItem("employeetype");
    localStorage.removeItem("user_id");
      };
    
      const value = {
        logout,
      };

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

export const useStateContext = () => useContext(StateContext);
