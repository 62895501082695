import React, { useEffect } from "react";
import "./uploadfile.css";
import { useUploadFile } from "../../../hooks/uploadfile";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { initialFileUpload, uploadFileSchema } from "../../../validations";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../utls/Context";

const UploadFile = () => {
  const navigate = useNavigate();
  const {logout}= useStateContext()

  
  const {
    mutateAsync: muteUpload,
    isLoading,
    isSuccess,
    isError,
    data: uploaddata,
    error,
  } = useUploadFile();

  const {
    resetForm,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue, // Add setFieldValue from Formik
  } = useFormik({
    initialValues: initialFileUpload,
    validationSchema: uploadFileSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("ristaCsv", values.ristaCsv);
        formData.append("upiCsv", values.upiCsv);
        formData.append("rozarPayCsv", values.rozarPayCsv);

        await muteUpload(formData);
        resetForm(); // Reset the form after successful submission

        document.getElementById("ristaCsv").value = "";
        document.getElementById("upiCsv").value = "";
        document.getElementById("rozarPayCsv").value = "";

        setFieldValue("ristaCsv", null);
        setFieldValue("upiCsv", null);
        setFieldValue("rozarPayCsv", null);
      } catch (error) {
        console.error("Error during file upload:", error);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = uploaddata;
      toast.success(message);

      if (status === 200) {
        resetForm();
      }
    }

    if (isError) {
      toast.error(error.response.data.message);
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout()
      }
    }
  }, [uploaddata, isError, isSuccess, resetForm, navigate, error, logout]);
  return (
    <div>
      <div className="uploadfile">
        <div className="uploadwrapper">
          <div className="heading">
            <p>Upload File</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="fileinput">
              <div className="label">
                <label>Upload Rista CSV</label>
                <label style={{ marginRight: "10px" }}>:</label>
              </div>
              <input
                type="file"
                placeholder="Upload Rista CSV"
                name="ristaCsv"
                id="ristaCsv"
                // value={values.ristaCsv}
                onChange={(event) => {
                  setFieldValue("ristaCsv", event.currentTarget.files[0]); // Use setFieldValue to update Formik values
                }}
                onBlur={handleBlur}
              />
              {errors.ristaCsv && touched.ristaCsv && (
                <div className="error-message">{errors.ristaCsv}</div>
              )}
            </div>

            <div className="fileinput">
              <div className="label">
                <label>Upload Upi CSV</label>
                <label style={{ marginRight: "10px" }}>:</label>
              </div>
              <input
                type="file"
                placeholder="Upload Upi CSV"
                name="upiCsv"
                id="upiCsv"
                // value={values.upiCsv}
                onChange={(event) => {
                  setFieldValue("upiCsv", event.currentTarget.files[0]); // Use setFieldValue to update Formik values
                }}
                onBlur={handleBlur}
              />
              {errors.upiCsv && touched.upiCsv && (
                <div className="error-message">{errors.upiCsv}</div>
              )}
            </div>

            <div className="fileinput">
              <div className="label">
                <label>Upload RozarPay CSV</label>
                <label style={{ marginRight: "10px" }}>:</label>
              </div>
              <input
                type="file"
                placeholder="Upload RozarPay CSV"
                name="rozarPayCsv"
                id="rozarPayCsv"
                // value={values.upiCsv}
                onChange={(event) => {
                  setFieldValue("rozarPayCsv", event.currentTarget.files[0]); // Use setFieldValue to update Formik values
                }}
                onBlur={handleBlur}
              />
              {errors.rozarPayCsv && touched.rozarPayCsv && (
                <div className="error-message">{errors.rozarPayCsv}</div>
              )}
            </div>

            <div className="button">
              <button type="submit" className="btt">
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Submit Now"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
