import { useMutation, useQuery } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";
const fatchBandDetails = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.BAND_DETAILS}/?pageSize=0`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useBandDetails = () => {
  return useQuery({
    queryKey: ["useBandDetails"],
    queryFn: (params) => fatchBandDetails(params),
  });
};

const fetchBandColour = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.FETCH_BAND,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useBandColour = () => {
  return useMutation({
    queryKey: ["useBandColour"],
    mutationFn: (params) => fetchBandColour(params),
  });
};

export { useBandDetails, useBandColour };
