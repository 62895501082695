import React, { useEffect } from "react";
import {
  initialProfileImgUpload,
  profileImgUploadSchema,
} from "../../validations";
import { useImgUpload } from "../../hooks/profiles";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const Uploadimg = ({ handleCloses }) => {
  const {
    mutateAsync: muteUploadImg,
    isLoading,
    isSuccess,
    isError,
    data: uploadimg,
  } = useImgUpload();

  useEffect(() => {
    if (isSuccess) {
      const { status, message } = uploadimg;
      toast.success(message);

      if (status === 200) {
        resetForm();
        handleCloses();
      }
    }

    if (isError) {
      toast.error("Something went wrong");
    }
    // eslint-disable-next-line
  }, [isError, isSuccess]);

  const {
    resetForm,
    // values,
    errors,
    touched,
    // handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue, // Add setFieldValue from Formik
  } = useFormik({
    initialValues: initialProfileImgUpload,
    validationSchema: profileImgUploadSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("img", values.img);

        // eslint-disable-next-line
        const response = await muteUploadImg(formData);
      } catch (error) {
        console.error("Error during file upload:", error);
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <div>
      <div className="modal" tabIndex="-1" style={{ height: "auto" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Profile Image</h5>
              <button
                type="button"
                className="cancelbtt"
                onClick={handleCloses}
              >
                <i
                  className="fa-solid fa-xmark fa-xl"
                  style={{ color: "var(--secondary-text-color)" }}
                ></i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="fileinput">
                  <input
                    type="file"
                    placeholder="Upload Profile Img"
                    name="img"
                    onChange={(event) => {
                      setFieldValue("img", event.currentTarget.files[0]); // Use setFieldValue to update Formik values
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.img && touched.img && (
                    <div className="error-message">{errors.img}</div>
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btt" type="submit" onClick={handleSubmit}>
                {" "}
                {isLoading ? (
                  <BeatLoader size={8} color={"#ffffff"} />
                ) : (
                  "Sumbit Now"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uploadimg;
