import { useMutation, useQuery } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";
const fatchComboDetails = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: `${ENDPOINTS.COMBO_TYPE}/?pageSize=0`,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useComboTypeDetails = () => {
  return useQuery({
    queryKey: ["useComboTypeDetails"],
    queryFn: (params) => fatchComboDetails(params),
  });
};

const fetchComboType = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.COMBO_TYPE,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useComboType = () => {
  return useMutation({
    queryKey: ["useComboType"],
    mutationFn: (params) => fetchComboType(params),
  });
};

export { useComboTypeDetails, useComboType };
