import { useMutation, useQuery } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

// addemployee api call (POST WITH DATA)---
const fetchSignup = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.ADDEMPLOYEE,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during signup:", error);
    throw error;
  }
};

const useSingUp = () => {
  return useMutation({
    queryKey: ["useSingUp"],
    mutationFn: (params) => fetchSignup(params),
  });
};

// login api call (POST WITH DATA)---
const fetchLogin = async (params) => {
  const { data } = await axiosInstance({
    method: "post",
    url: ENDPOINTS.LOGIN,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useLogin = () => {
  return useMutation({
    queryKey: ["useLogin"],
    mutationFn: (params) => fetchLogin(params),
  });
};

// GET PROFILE DATA
const profileDetails = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.PROFILE,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useProfileDetails = () => {
  return useQuery({
    queryKey: ["useProfileDetails"],
    queryFn: (params) => profileDetails(params),
  });
};

const fetchEmployeeList = async (filter) => {
  const filterParam = filter ? `{"employeetype":"${encodeURIComponent(filter)}"}` : '';
  
  // Construct the URL
  const url = `${ENDPOINTS.EMPLOYEE_list}?pageSize=0&filter=${filterParam}`;
  
  const { data } = await axiosInstance({
    method: "get",
  //  url : `${ENDPOINTS.EMPLOYEE_list}?pageSize=0${filter ? `&filter={employeetype:encodeURIComponent(${filter }})` : ''}`,
    url: url,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useEmployeeList = (filter) => {
  return useQuery({
    queryKey: ["useEmployeeList",filter],
    queryFn: () => fetchEmployeeList(filter),
  });
};

const updateEmployee = async (params) => {
  console.log("🚀 ~ updateEmployee ~ params:", params)
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.EMPLOYEE}${params.id}`,
      data: params?.values,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during signup:", error);
    throw error;
  }
};

const useUpdateEmployee = () => {
  return useMutation({
    queryKey: ["useUpdateEmployee"],
    mutationFn: (params) => updateEmployee( params),
  });
};

export { useSingUp, useLogin, useProfileDetails ,useEmployeeList,useUpdateEmployee};
