import { useMutation, useQuery } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

const fetchProfileDetails = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.PROFILE_DETAILS,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useProfileDetailsData = () => {
  return useQuery({
    queryKey: ["useProfileDetailsData"],
    queryFn: (params) => fetchProfileDetails(params),
  });
};

// upload profile img upload

const fetchImgUpload = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.PROFILE_UPLOAD_IMG,
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useImgUpload = () => {
  return useMutation({
    queryKey: ["useImgUpload"],
    mutationFn: (params) => fetchImgUpload(params),
  });
};

export { useProfileDetailsData, useImgUpload };
