import { useMutation, useQuery } from "react-query";
import axiosInstance from "../services/axiosInstance";
import ENDPOINTS from "../services/endPoints";

// create add report

const fetchAddReport = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.ADDREPORT,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useAddReport = () => {
  return useMutation({
    queryKey: ["useAddreport"],
    mutationFn: (params) => fetchAddReport(params),
  });
};

// non ticket collector

const fetchNonTicket = async (params) => {
  try {
    const { data } = await axiosInstance({
      method: "post",
      url: ENDPOINTS.NON_TICKET_COLLECTOR,
      data: params,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useNonTicket = () => {
  return useMutation({
    queryKey: ["useNonTicket"],
    mutationFn: (params) => fetchNonTicket(params),
  });
};

// get add report
const addReportData = async (params) => {
  const { data } = await axiosInstance({
    method: "get",
    url: ENDPOINTS.ADDREPORTDETAILS,
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

const useGetAddReport = () => {
  return useQuery({
    queryKey: ["useGetAddReport"],
    queryFn: (params) => addReportData(params),
  });
};

// editreport
const editDSLNonReport = async (dataid, payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.EDIT_DSLNONREPORT}${dataid}`,
      data: payload,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useDSLNonReport = () => {
  return useMutation({
    queryKey: ["useDSLNonReport"],
    mutationFn: ({ dataid, payload }) => editDSLNonReport(dataid, payload),
  });
};

const editDSLReport = async (dataid, payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `${ENDPOINTS.EDIT_DSLREPORT}${dataid}`,
      data: payload,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    // Handle errors if necessary
    console.error("Error during addreport:", error);
    throw error;
  }
};

const useDSLReport = () => {
  return useMutation({
    queryKey: ["useDSLReport"],
    mutationFn: ({ dataid, payload }) => editDSLReport(dataid, payload),
  });
};

export {
  useAddReport,
  useGetAddReport,
  useNonTicket,
  useDSLNonReport,
  useDSLReport,
};
