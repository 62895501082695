import React from "react";
import LoginForm from "./LoginForm";
import "./Login.css";

const Login = () => {
  return (
    <div>
      <div className="loginform">
        <div className="formwrapper">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
