import * as Yup from "yup";

// initial values
const initialValuesLogin = {
  userName: "",
  password: "",
};

const initialValuesAddEmployeeDetails = {
  userName: "",
  name: "",
  phone: "",
  password: "",
  employeetype: "",
  // ticketcollector:""
};
const initialValuesupdateEmployeeDetails = {
  password: "",
}

// add report

const initialValuesAddReport = {
  cashFlowDetails: {
    counterName: "",
    // notes2000: "",
    notes500: "",
    notes200: "",
    notes100: "",
    notes50: "",
    notes20: "",
    notes10: "",
    notes5: "",
    notes2: "",
    notes1: "",
    cash: "",
    upi: "",
    razorpay: "",
    totalCash: "",
  },
  ticketDetails: [],
  partyDetails: [],
};

// non ticket collector
const initialValuesNonTicket = {
  cashFlowDetails: {
    counterName: "",
    // notes2000: "",
    notes500: "",
    notes200: "",
    notes100: "",
    notes50: "",
    notes20: "",
    notes10: "",
    notes5: "",
    notes2: "",
    notes1: "",
    cash: "",
    upi: "",
    razorpay: "",
    voucher: "",
    totalCash: "",
  },
  onlineDeliveryOrder: [],
  complimentaryDetails: [],
};

// partydetails
const initialPartyDetails = {
  email: "",
  eventBookBy: "",
  dateOfEvent: "",
  typeOfEvent: "",
  designatedArea: "",
  nameOfCustomer: "",
  nameOfVendor: "",
  phone: "",
  activitiesZipline: "",
  activitiesZipcycle: "",
  activitiesBullRide: "",
  activitiesBodyZorbing: "",
  activitiesLaserTag: "",
  activitiesMagicShow: "",
  activitiesBallonArt: "",
  activitiesTattooPaintaing: "",
  activitiesPottery: "",
  activitiesDJ: "",
  activitiesBartender: "",
  noAdults: "",
  noKids: "",
  adultPrice: "",
  kidsPrice: "",
  otherService: "",
  totalBillingAmount: "",
  DateOfAdvance: "",
  advanceAmount: "",
  ModeOFAdvancePayment: "",
  finalAmountPaid: "",
  modeOfFinalPayment: "",
  discount: "",
  serviceIssues: "",
  eventCoordinator: "",
  paymentCollectedBy: "",
  staffAvailableInParty: "",
  UplaoadGuestList: "",
};

const initialforgetpasword = {
  phone: "",
};
const initialotp = {
  otp: "",
};
const initialpasswordchange = {
  password: "",
};

const initialCounterName = {
  counterName: "",
};

// uploadfile intial
const initialFileUpload = {
  ristaCsv: "",
  upiCsv: "",
  rozarPayCsv: "",
};

//uploadfile Validation
const uploadFileSchema = Yup.object()
  .shape({
    ristaCsv: Yup.mixed(),
    upiCsv: Yup.mixed(),
    rozarPayCsv:Yup.mixed(),
  })
  .test(
    "at-least-one-required",
    "At least one CSV file is required",
    function (value) {
      if (!value.ristaCsv && !value.upiCsv  && !value.rozarPayCsv) {
        return false;
      }
      return true;
    }
  );

// uploadprofile img

const initialProfileImgUpload = {
  img: "",
};

const profileImgUploadSchema = Yup.object().shape({
  img: Yup.mixed().required("Image is required"),
});

// counter type

const initialcounter = {
  counterName: "",
  counterType: "",
};

const counterSchema = Yup.object().shape({
  counterName: Yup.string().required("Counter Name is required *"),
  counterType: Yup.string().required("Counter Typeis required *"),
});

const initialBandColour = {
  ticketBnadColour: "",
};
const bandColourSchema = Yup.object().shape({
  ticketBnadColour: Yup.string().required("Band Colour is required *"),
});

const initialComboType = {
  ticketComboType: "",
};
const comboTypeSchema = Yup.object().shape({
  ticketComboType: Yup.string().required("Band Colour is required *"),
});

// validation schemas
const LoginSchema = Yup.object().shape({
  userName: Yup.string().required("Username is required *"),
  password: Yup.string()
    .min(6, "Password must be 6 characters at minimum *")
    .required("Password is required * "),
  // employeetype:Yup.string().required("Employee Type is Required"),
});
const AddEmployeeSchema = Yup.object().shape({
  userName: Yup.string().required("Username is required *"),
  name: Yup.string().required("Name is required *"),
  phone: Yup.string()
    .length(10, "Phone number must be 10 digits")
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .required("Phone number is required *"),
  password: Yup.string()
    .min(6, "Password must be 6 characters at minimum *")
    .required("Password is required * "),
  employeetype: Yup.string()
    .oneOf(
      ["POS Employee", "Opertional Manager", "Financial Manager"],
      "Invalid Employee Type"
    )
    .required("Employee Type is required * "),
});

const validationSchemaAddReport = Yup.object().shape({
  cashFlowDetails: Yup.object().shape({
    counterName: Yup.string().required("Counter Name is required"),
    // notes2000: Yup.number()
    // .typeError("Please enter a valid number")

    // .integer("Please enter a valid integer")
    // .required("No of 2000 Notes required"),
    notes500: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 500 Notes required"),
    notes200: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 200 Notes required"),
    notes100: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 100 Notes required"),
    notes50: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 50 Notes required"),
    notes20: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 20 Notes required"),
    notes10: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 10 Notes required"),
    notes5: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 5 Notes required"),
    notes2: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 2 coins required"),
    notes1: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 1 coins required"),
    cash: Yup.number()
      .typeError("Please enter a valid number")
      .required("Cash is required"),
    upi: Yup.number()
      .typeError("Please enter a valid number")
      .required("Upi is required"),
    razorpay: Yup.number()
      .typeError("Please enter a valid number")
      .required("Razorpay is required"),
    totalCash: Yup.number()
      .typeError("Please enter a valid number")
      .required("Total cash required"),
  }),
  ticketDetails: Yup.array().of(
    Yup.object().shape({
      comboType: Yup.string().required("Combo Type is required"),
      bandColour: Yup.string().required("Band Colour is required"),
      ticketOpeningNo: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")

        .required("Ticket Opening No. is required"),
      ticketClosingNo: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")

        .required("Ticket Closing No. is required")
        .test(
          "greaterThanOpening",
          "Band Closing No. >= Band Opening No.",
          function (value) {
            const openingNo = this.parent.ticketOpeningNo;
            return value >= openingNo;
          }
        ),
      damage: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("Damage is required"),
      extendedTicket: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("Extended Ticket is required"),
      totalComplimentary: Yup.string().required("comp. is required"),
      complimentBy: Yup.string().required("comp. by is required"),
      // annualPass: Yup.number()
      // .typeError("Please enter a valid number")

      // .integer("Please enter a valid integer")

      // .required("Annual Pass is required"),
      totalUsedBands: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("Total Used Bands is required"),
      totalBands: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("Total Bands is required"),
    })
  ),
  partyDetails: Yup.array().of(
    Yup.object().shape({
      partyName: Yup.string().required("Party Name is required"),
      // pnotes2000: Yup.number()
      // .typeError("Please enter a valid number")

      // .integer("Please enter a valid integer")
      //   .required("No of 2000 Notes required"),
      pnotes500: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 500 Notes required"),
      pnotes200: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 200 Notes required"),
      pnotes100: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 100 Notes required"),
      pnotes50: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 50 Notes required"),
      pnotes20: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 20 Notes required"),
      pnotes10: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 10 Notes required"),
      pnotes5: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 5 Notes required"),
      pnotes2: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 2 Coins required"),
      pnotes1: Yup.number()
        .typeError("Please enter a valid number")

        .integer("Please enter a valid integer")
        .required("No of 1 coins required"),
      pcash: Yup.number()
        .typeError("Please enter a valid number")
        .required("Cash is required"),
      pupi: Yup.number()
        .typeError("Please enter a valid number")
        .required("upi required"),
      prazorpay: Yup.number()
        .typeError("Please enter a valid number")
        .required("Razorpay is required"),
      ptotalCash: Yup.number()
        .typeError("Please enter a valid number")
        .required("Total cash required"),
    })
  ),
});

// non ticket collector
const validationSchemaNonTicket = Yup.object().shape({
  cashFlowDetails: Yup.object().shape({
    counterName: Yup.string().required("Counter Name is required"),
    // notes2000: Yup.number()
    // .typeError("Please enter a valid number")

    // .integer("Please enter a valid integer")
    // .required("No of 2000 Notes required"),
    notes500: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 500 Notes required"),
    notes200: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 200 Notes required"),
    notes100: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 100 Notes required"),
    notes50: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 50 Notes required"),
    notes20: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 20 Notes required"),
    notes10: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 10 Notes required"),
    notes5: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 5 Notes required"),
    notes2: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 2 coins required"),
    notes1: Yup.number()
      .typeError("Please enter a valid number")

      .integer("Please enter a valid integer")
      .required("No of 1 coins required"),
    cash: Yup.number()
      .typeError("Please enter a valid number")
      .required("Cash is required"),
    upi: Yup.number()
      .typeError("Please enter a valid number")
      .required("Upi is required"),
    razorpay: Yup.number()
      .typeError("Please enter a valid number")
      .required("Razorpay is required"),
    voucher: Yup.number()
      .typeError("Please enter a valid number")
      .required("voucher is required"),

    totalCash: Yup.number()
      .typeError("Please enter a valid number")
      .required("Total cash required"),
  }),

  onlineDeliveryOrder: Yup.array().of(
    Yup.object().shape({
      invoice: Yup.string().required("Invoice is required"),
      amount: Yup.number()
        .typeError("Please enter a valid number")
        .required("Amount is required"),
      aggregator: Yup.string().required("Aggregator is required"),
      remarks: Yup.string().required("Remarks is required"),
    })
  ),
  complimentaryDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      items: Yup.string().required("Items is required"),
      quantity: Yup.number()
        .typeError("Please enter a valid number")
        .integer("Please enter a valid integer")

        .required("Quantity is required"),
      unitCost: Yup.number()
        .typeError("Please enter a valid number")
        .required("Unit Cost is required"),
      givenBy: Yup.string().required("Given By is required"),
      remarks: Yup.string()
        .nullable()
        .notRequired()
        .transform((value, originalValue) => {
          // Transform empty string to null
          return originalValue?.trim() === "" ? null : value;
        }),
    })
  ),
});

//partydetails validation

const partyDetailsSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  eventBookBy: Yup.string().required("Party Name is required"),
  dateOfEvent: Yup.string().required("Event Date is required"),
  typeOfEvent: Yup.string().required("Type of Event is required"),
  designatedArea: Yup.string().required("DesignatedArea is required"),
  nameOfCustomer: Yup.string().required("Name Of Customer is required"),
  nameOfVendor: Yup.string().required("Name of Vendor is required"),
  phone: Yup.string()
    .length(10, "Phone number must be 10 digits")
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .required("Phone is required"),
  activitiesZipline: Yup.string().required("Zipline is required"),
  activitiesZipcycle: Yup.string().required("Zipcycle is required"),
  activitiesBullRide: Yup.string().required("BullRide is required"),
  activitiesBodyZorbing: Yup.string().required("Zorbing is required"),
  activitiesLaserTag: Yup.string().required("LaserTag is required"),
  activitiesMagicShow: Yup.string().required("Magic Show is required"),
  activitiesBallonArt: Yup.string().required("BallonArt is required"),
  activitiesTattooPaintaing: Yup.string().required("Painting is required"),
  activitiesPottery: Yup.string().required("Pottery is required"),
  activitiesDJ: Yup.string().required("DJ is required"),
  activitiesBartender: Yup.string().required("Bartender is required"),
  noAdults: Yup.number()
    .typeError("Please enter a valid number")

    .integer("Please enter a valid integer")

    .required("No. of Adult is required"),
  noKids: Yup.number()
    .typeError("Please enter a valid number")

    .integer("Please enter a valid integer")
    .required("No. Kids is required"),
  adultPrice: Yup.string().required("Adult Price is required"),
  kidsPrice: Yup.string().required("Kids Price is required"),
  otherService: Yup.string().required("Other Services is required"),
  totalBillingAmount: Yup.string().required("Total Amount  is required"),
  DateOfAdvance: Yup.string().required("Date of Adv. is required"),
  advanceAmount: Yup.string().required("Advance is required"),
  ModeOFAdvancePayment: Yup.string().required("Mode Of Payment is required"),
  finalAmountPaid: Yup.string().required("Final Amount is required"),
  modeOfFinalPayment: Yup.string().required("Mode Of Payment is required"),
  discount: Yup.string().required("Discount is required"),
  serviceIssues: Yup.string().required("Service Issues is required"),
  eventCoordinator: Yup.string().required("Event Coordinator is required"),
  paymentCollectedBy: Yup.string().required("Payment Collected by is required"),
  staffAvailableInParty: Yup.string().required(
    "Staff Avalable in party  is required"
  ),
  UplaoadGuestList: Yup.string().required("Guest List is required"),
});

// password change schema

const forgetPasswordSchema = Yup.object().shape({
  phone: Yup.string()
    .length(10, "Phone number must be 10 digits")
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .required("Phone number is required *"),
});
const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .length(6, "otp must be 6 digits")
    .matches(/^[0-9]+$/, "otp must only contain digits")
    .required("otp is required *"),
});
const passwordChangeSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be 6 characters at minimum *")
    .required("Password is required * "),
});

const counterNameSchema = Yup.object().shape({
  counterName: Yup.string().required("Counter name is require"),
});

const updateEmployeeSchema = Yup.object().shape({
  password: Yup.string().required("Password is required *"),
})

export {
  initialValuesLogin,
  LoginSchema,
  initialValuesAddEmployeeDetails,
  AddEmployeeSchema,
  initialValuesAddReport,
  validationSchemaAddReport,
  initialPartyDetails,
  partyDetailsSchema,
  initialforgetpasword,
  forgetPasswordSchema,
  initialotp,
  otpSchema,
  initialpasswordchange,
  passwordChangeSchema,
  initialCounterName,
  counterNameSchema,
  initialValuesNonTicket,
  validationSchemaNonTicket,
  initialFileUpload,
  uploadFileSchema,
  initialProfileImgUpload,
  profileImgUploadSchema,
  initialcounter,
  counterSchema,
  initialBandColour,
  bandColourSchema,
  initialComboType,
  comboTypeSchema,
  initialValuesupdateEmployeeDetails,
  updateEmployeeSchema,
};
