import React, { useEffect, useState } from "react";
import "./pointofsale.css";
import { useProfileDetails } from "../../hooks/addemployee";
import { useGetAddReport } from "../../hooks/addreport";
import "react-toastify/dist/ReactToastify.css";
import CounterName from "../../components/counterName/CounterName";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStateContext } from "../../utls/Context";

const Index = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addreport, setAddreport] = useState([]);
  const {logout}= useStateContext()


  const data = useProfileDetails();
  const { data: addreports, isError, error } = useGetAddReport();
  useEffect(() => {
    setAddreport(addreports);
  }, [addreports]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        navigate("/");
        logout()
      }
    }
  }, [isError, error, navigate, logout]);

  const handleAddReportClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const calculateCurrencyTotal = (notes, valuePerNote) => {
    return notes * valuePerNote;
  };

  return (
    <div>
      <div className="wrappers">
        {/* <NavbarPoint/> */}
        <div className="detailswrapper">
          <h3> Welcome {data?.data?.data?.name}</h3>

          <div className="details">
            <div className="detailsinfo">
              <div className="detailsheading">
                <button className="btt" onClick={handleAddReportClick}>
                  Add today report
                </button>
              </div>
              {addreport?.data?.totalCount > 0 ? (
                addreport?.data?.data?.map((item, index) => {
                  return (
                    <div className="detailscontent" key={index}>
                      <div className="cashdetails">
                        <div className="cashflow">
                          <h2>Cash flow Details:-</h2>
                          <h3 className="counter">
                            {" "}
                            Counter Name:{item?.cashFlowDetails?.counterName}
                          </h3>

                          <table>
                            <tr>
                              <th>Currency Type</th>
                              <th>Notes </th>
                              <th>Total</th>
                            </tr>

                            <tr>
                              <td>500</td>
                              <td>{item?.cashFlowDetails?.notes500}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes500,
                                  500
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>200</td>
                              <td>{item?.cashFlowDetails?.notes200}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes200,
                                  200
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>100</td>
                              <td>{item?.cashFlowDetails?.notes100}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes100,
                                  100
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>50</td>
                              <td>{item?.cashFlowDetails?.notes50}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes50,
                                  50
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>20</td>
                              <td>{item?.cashFlowDetails?.notes20}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes20,
                                  20
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>{item?.cashFlowDetails?.notes10}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes10,
                                  10
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>{item?.cashFlowDetails?.notes5}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes5,
                                  5
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>{item?.cashFlowDetails?.notes2}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes2,
                                  2
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>{item?.cashFlowDetails?.notes1}</td>
                              <td>
                                {calculateCurrencyTotal(
                                  item?.cashFlowDetails?.notes1,
                                  1
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Cash</td>
                              <td></td>
                              <td>{item?.cashFlowDetails?.cash}</td>
                            </tr>
                            <tr>
                              <td>UPI</td>
                              <td></td>
                              <td>{item?.cashFlowDetails?.upi}</td>
                            </tr>
                            <tr>
                              <td>Razorpay</td>
                              <td></td>
                              <td>{item?.cashFlowDetails?.razorpay || 0}</td>
                            </tr>
                            {item?.cashFlowDetails?.voucher || 0 ? (
                              <tr>
                                <td>Voucher</td>
                                <td></td>
                                <td>{item?.cashFlowDetails?.voucher}</td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>Grand Total</td>
                              <td></td>
                              <td>{item?.cashFlowDetails?.totalCash}</td>
                            </tr>
                          </table>
                        </div>

                        {item.partyDetails.length > 0 ? (
                          <div className="partydetails">
                            <h2>Party details:-</h2>
                            <h3 className="partyname">
                              Party Name:
                              {item.partyDetails
                                .map(
                                  (partyDetails, partyIndexs) =>
                                    partyDetails?.partyName
                                )
                                .join(", ")}
                            </h3>
                            <table>
                              <tr>
                                <th>Currency Type</th>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <th>Notes </th>

                                      <th>Total</th>
                                    </>
                                  )
                                )}
                              </tr>

                              {/* <tr>
                              <td>2000</td>
                              {item.partyDetails.map((partyDetail, partyIndex) => (
                                <>
                              <td>{partyDetail?.pnotes2000}</td>
                              <td>{calculateCurrencyTotal(partyDetail?.pnotes2000, 2000)}</td>
                              </>
                              ))}
                            </tr> */}
                              <tr>
                                <td>500</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes500}</td>
                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes500,
                                          500
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>200</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes200}</td>
                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes200,
                                          200
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>100</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes100}</td>
                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes100,
                                          100
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>50</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes50}</td>
                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes50,
                                          50
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>20</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail.pnotes20}</td>
                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes20,
                                          20
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>10</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes10}</td>
                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes10,
                                          10
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>5</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes5}</td>

                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes5,
                                          5
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>2</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes2}</td>

                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes2,
                                          2
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>1</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td>{partyDetail?.pnotes1}</td>

                                      <td>
                                        {calculateCurrencyTotal(
                                          partyDetail?.pnotes1,
                                          1
                                        )}
                                      </td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>Cash</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td></td>
                                      <td>{partyDetail?.pcash}</td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>UPI</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td></td>
                                      <td>{partyDetail?.pupi}</td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>Razorpay</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td></td>
                                      <td>{partyDetail?.prazorpay}</td>
                                    </>
                                  )
                                )}
                              </tr>
                              <tr>
                                <td>Grand Total</td>
                                {item.partyDetails.map(
                                  (partyDetail, partyIndex) => (
                                    <>
                                      <td></td>
                                      <td>{partyDetail?.ptotalCash}</td>
                                    </>
                                  )
                                )}
                              </tr>
                            </table>
                          </div>
                        ) : (
                          item?.onlineDeliveryOrder.length > 0 && (
                            <div className="partydetails">
                              <h2>Online Delivery Order:-</h2>
                              <table>
                                <tr>
                                  <th style={{ fontSize: "16px" }}>Invoice</th>

                                  <th style={{ fontSize: "16px" }}>Amount.</th>
                                  <th style={{ fontSize: "16px" }}>
                                    Aggregator
                                  </th>
                                  <th style={{ fontSize: "16px" }}>Remarks</th>
                                </tr>

                                {item?.onlineDeliveryOrder?.map(
                                  (onlineorder, onlineIndex) => {
                                    return (
                                      <tr key={onlineIndex}>
                                        <td>{onlineorder?.invoice}</td>

                                        <td>{onlineorder?.amount}</td>
                                        <td>{onlineorder?.aggregator}</td>

                                        <td>{onlineorder?.remarks}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </table>

                              {/* <table>
                                <tr>
                                  <th>title</th>

                                  {item?.onlineDeliveryOrder?.map(
                                    (onlineorder, onlineIndex) => {
                                      return <th>des</th>;
                                    }
                                  )}
                                </tr>

                                <tr>
                                  <td>Invoice</td>
                                  {item?.onlineDeliveryOrder?.map(
                                    (onlineorder, onlineIndex) => {
                                      return <td>{onlineorder?.invoice}</td>;
                                    }
                                  )}
                                </tr>

                                <tr>
                                  <td>amount</td>
                                  {item?.onlineDeliveryOrder?.map(
                                    (onlineorder, onlineIndex) => {
                                      return <td>{onlineorder?.amount}</td>;
                                    }
                                  )}
                                </tr>

                                <tr>
                                  <td>Mode Of Payment</td>
                                  {item?.onlineDeliveryOrder?.map(
                                    (onlineorder, onlineIndex) => {
                                      return <td>{onlineorder?.modeOfPayment}</td>;
                                    }
                                  )}
                                </tr>

                                <tr>
                                  <td>Remarks</td>
                                  {item?.onlineDeliveryOrder?.map(
                                    (onlineorder, onlineIndex) => {
                                      return <td>{onlineorder?.remarks}</td>;
                                    }
                                  )}
                                </tr>
                              </table> */}
                            </div>
                          )
                        )}
                      </div>

                      {item.complimentaryDetails.length > 0 && (
                        <div className="ticketdetails">
                          <h2> Complimentary Details:- </h2>
                          <div className="tables">
                            <table>
                              <tr>
                                <th>Name</th>
                                <th>Items</th>
                                <th>Quantity</th>
                                <th>Unit Cost</th>
                                <th>Amount</th>
                                <th>Given By</th>
                                <th>Remarks</th>
                              </tr>
                              {item.complimentaryDetails.map(
                                (compliment, complimentindex) => (
                                  <tr key={complimentindex}>
                                    <td>{compliment?.name}</td>
                                    <td>{compliment?.items}</td>
                                    <td>{compliment.quantity}</td>
                                    <td>{compliment?.unitCost}</td>
                                    <td>
                                      {calculateCurrencyTotal(
                                        compliment?.quantity,
                                        compliment?.unitCost
                                      ).toFixed(2)}
                                    </td>
                                    <td>{compliment?.givenBy}</td>
                                    <td>{compliment?.remarks}</td>
                                  </tr>
                                )
                              )}
                            </table>
                          </div>
                        </div>
                      )}

                      {item.ticketDetails.length > 0 && (
                        <div className="ticketdetails">
                          <h2> Ticket details:- </h2>
                          <div className="tables">
                            <table>
                              <tr>
                                <th>Combo Type</th>
                                <th>Band Colour</th>
                                <th>Opening</th>
                                <th>Closing</th>
                                <th>Damage</th>
                                <th>Extended Ticket</th>
                                <th>Compliment</th>
                                <th>Compliment By</th>

                                {/* <th>Annual Pass</th> */}
                                <th>Used Bands</th>
                                <th>Total Bands</th>
                              </tr>
                              {item.ticketDetails.map((ticket, ticketIndex) => (
                                <tr key={ticketIndex}>
                                  <td>{ticket?.comboType}</td>
                                  <td>{ticket?.bandColour}</td>
                                  <td>{ticket.ticketOpeningNo}</td>
                                  <td>{ticket?.ticketClosingNo}</td>
                                  <td>{ticket?.damage}</td>
                                  <td>{ticket?.extendedTicket}</td>
                                  <td>{ticket?.totalComplimentary}</td>
                                  <td>{ticket?.complimentBy}</td>

                                  {/* <td>{ticket?.annualPass}</td> */}
                                  <td>{ticket?.totalUsedBands}</td>
                                  <td>{ticket?.totalBands}</td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="follow-loading-div">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60vh",
                      color: "var(--secondary-text-color)",
                      fontFamily: "Inter",
                    }}
                  >
                    <img
                      src="/assest/noComments.svg"
                      alt="comments"
                      style={{
                        width: "123px",
                        height: "101px",
                      }}
                    />
                    <h1
                      style={{
                        lineHeight: "21px",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "400",
                      }}
                    >
                      POS was not adding any report.
                    </h1>
                    <span style={{ fontSize: "20px", fontWeight: "300" }}>
                      When POS Add any report they’ll show here.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <CounterName handleClose={handleAddReportClick} />}
    </div>
  );
};

export default Index;
