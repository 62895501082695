import React from 'react'
import { Navigate } from 'react-router-dom'

function ProtectiveRoutes({children}) {
    const userId = localStorage.getItem("user_id")
    if (!userId) {
        return <Navigate to="/" />
    }

  return children;
}

export default ProtectiveRoutes